import { Button } from '@/ui/button';
import React from 'react';

export default function LayoutButton({
  children,
  path,
}: {
  children: React.ReactNode[] | React.ReactNode;
  path: string;
}) {
  const isActive = window.location.pathname === path;

  return (
    <Button className="p-0 text-stone-500 hover:bg-inherit active:bg-inherit" fill="ghost">
      <div
        className={`text-pi-primary-default" flex flex-col items-center  justify-center font-semibold ${
          isActive ? 'text-pi-primary-default' : ''
        }`}
      >
        {children}
      </div>
    </Button>
  );
}
