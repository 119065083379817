import * as React from 'react';

import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

const inputVariants = cva(
  'text-md ring-offset-background placeholder:text-primary-hint mt-1 flex h-10 w-full rounded-md border border-input-border p-3 text-input-content file:border-0 file:bg-transparent file:text-sm file:font-medium focus:border-input-border-active focus:bg-input-background-active focus:text-input-content-active focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed',
  {
    variants: {
      disabled: {
        true: 'border-input-border-disabled bg-primary-disabled text-primary-content-disabled',
        false: null,
      },
      'aria-invalid': {
        true: 'border-input-border-error focus:border-input-border-error',
        false: null,
      },
    },
  }
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ ...props }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
