import LocalStorageServices from "@/services/localstorage.services";
import { useState } from "react";

export default function useLocalStorage() {
  const { getUserRole, setUserRole: setUserRoleItem } = LocalStorageServices();
  const storedValue = getUserRole();
  const currentUserRole = storedValue ? storedValue : "";
  const [value, setValue] = useState(currentUserRole);

  const setUserRole = (role: "sender" | "courier") => {
    setValue(role);
    setUserRoleItem(role);
  };

  return { value, setUserRole };
}
