import { TabsContent } from '@/ui/tabs';
import SentDeliveryCard from './sent-delivery-card';

import EmptyMessage from '@/components/custom/empty-message';
import { ShoppingBag } from 'lucide-react';
import { userIcon } from '@/assets/images';
import { Button } from '@/ui/button';
import { Delivery, useDeleteDeliveryMutation } from '@/services/delivery.services';
import { Link } from 'react-router-dom';
import { DeliveryFilter } from './delivery-filter';
import { useState } from 'react';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import ToastedAlert from '@/components/custom/ToastedAlert';
import toast from 'react-hot-toast';
import { Loader } from '@/components';

export default function SentContent({
  deliveryItems,
  onRefetch,
  isRefetching,
}: {
  deliveryItems: Delivery[] | undefined;
  onRefetch: () => void;
  isRefetching: boolean;
}) {
  const [filteredDeliveries, setFilteredDeliveries] = useState<Delivery[] | undefined>(
    deliveryItems
  );
  const [deleteDelivery, { isLoading, reset }] = useDeleteDeliveryMutation();

  const handleDeleteDelivery = async (deliveryId: string) => {
    try {
      await deleteDelivery({ id: deliveryId }).unwrap();
      reset();
      onRefetch();
    } catch (err) {
      toast.error(<ToastedAlert description="Error Deleting Delivery. Try again!" title="Error" />);
      console.log(err);
    }
  };

  if (isRefetching) {
    return <Loader />;
  }

  if (!deliveryItems || deliveryItems.length === 0) {
    return (
      <TabsContent className="transition-all delay-150 ease-in-out" value="sent">
        <section className="flex flex-col justify-center">
          <EmptyMessage icon={ShoppingBag} message="Oops! No delivery order sent yet!">
            <div className="flex flex-col items-center justify-center gap-16">
              <p className="text-center text-xs">
                Your package will be shown here attached with your delivery Vcode
              </p>
              <Link to="/sender">
                <Button className="rounded-lg">Request a Pailot</Button>
              </Link>
            </div>
          </EmptyMessage>
        </section>
      </TabsContent>
    );
  }

  const canDeleteDelivery = (delivery: Delivery) =>
    delivery.deliveryStatus === DeliveryStatus.CREATED && delivery.courier !== null;

  return (
    <TabsContent className="transition-all delay-150 ease-in-out" value="sent">
      <section className="flex flex-col justify-center gap-3">
        <div className="flex items-center justify-between">
          <h5 className="font-medium  text-gray-500">Outgoing Deliveries</h5>
          <DeliveryFilter deliveries={deliveryItems} onFilterDeliveries={setFilteredDeliveries} />
        </div>
        {filteredDeliveries && filteredDeliveries.length > 0 ? (
          filteredDeliveries.map((item) => {
            return (
              <SentDeliveryCard
                deliveryId={item.id}
                deliveryLocation={item.toAddress}
                onDeleteDelivery={canDeleteDelivery(item) ? handleDeleteDelivery : undefined}
                isDeleteLoading={canDeleteDelivery(item) ? isLoading : undefined}
                deliveryStatus={item.deliveryStatus}
                imageUrl={item.itemImage}
                itemName={item.itemName}
                key={item.id}
                price={item.transactionAmount}
                receiverImageUrl={item.receiverProfile.user.profileImgUrl || userIcon}
                receiverUsername={item.receiverProfile.user.username}
                preferredModeOfDelivery={item.preferredModeOfDelivery}
              />
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center gap-16">
            <p className="text-center text-xs">No items found.</p>
          </div>
        )}
      </section>
    </TabsContent>
  );
}
