import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const labelVariants = cva('text-sm font-normal leading-none text-input-content', {
  variants: {
    disabled: {
      true: 'text-primary-content-disabled',
      false: null,
    },
    invalid: {
      true: 'text-primary-content-error',
      false: null,
    },
  },
});

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => {
  const { disabled, invalid, ...transferableProps } = props;
  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants({ disabled, invalid }), className)}
      {...transferableProps}
    />
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
