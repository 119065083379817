import { User } from "@/Store/Features/authSlice";
import baseApi from "./base.services";
import {
  GET_CHATS_FOR_USER,
  GET_CHAT_BY_ID,
  GET_CHAT_MESSAGES,
  ARCHIVE_CHAT,
  MARK_MESSAGE_AS_READ,
  GET_UNREAD_MESSAGES,
} from "@/constants/config";
import { Delivery } from "./delivery.services";

export type ParticipantType = {
  id: string;
  chatId: ChatType;
  userId: User;
  role: "sender" | "receiver" | "courier";
};

export type ChatType = {
  id: string;
  createdAt: string;
  archived: boolean;
  messages: MessageType[];
  participants: ParticipantType[];
  latestMessage: MessageType | null;
  deliveryId: Delivery;
  unreadMessageCount: number;
};

export type MessageReadStatusType = {
  id: string;
  readAt: Date;
  messageId: string;
  userId: string;
};

export type MessageType = {
  type: "USER" | "SYSTEM";
  id: string;
  content: string;
  mediaUrl: string;
  mediaType: string;
  createdAt: string;
  chatId: ChatType;
  senderId?: User;
  readStatus: MessageReadStatusType[];
};

export const chatApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getChatsForUser: builder.query<ChatType[], void>({
      query: () => GET_CHATS_FOR_USER,
    }),
    getChatById: builder.query<ChatType, { id: string }>({
      query: ({ id }) => GET_CHAT_BY_ID(id),
      keepUnusedDataFor: 0,
    }),
    getChatMessages: builder.query<
      MessageType[],
      { chatId: string; page?: number }
    >({
      query: (args) => ({
        url: GET_CHAT_MESSAGES(args.chatId),
        method: "GET",
        params: { page: args.page },
      }),

      keepUnusedDataFor: 0,

      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // console.log(endpointName);
        return `${endpointName}/${queryArgs.chatId}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache = [...newItems, ...currentCache];
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.chatId !== previousArg?.chatId ||
          currentArg?.page !== previousArg?.page
        );
      },
    }),
    archiveChat: builder.mutation<ChatType, { id: string }>({
      query: ({ id }) => ({
        url: ARCHIVE_CHAT(id),
        method: "PUT",
      }),
    }),
    markMessageAsRead: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: MARK_MESSAGE_AS_READ(id),
        method: "PUT",
      }),
    }),
    getUnreadMessages: builder.query<
      MessageReadStatusType[],
      { chatId: string }
    >({
      query: ({ chatId }) => GET_UNREAD_MESSAGES(chatId),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetChatsForUserQuery,
  useGetChatByIdQuery,
  useGetChatMessagesQuery,
  useArchiveChatMutation,
  useMarkMessageAsReadMutation,
  useGetUnreadMessagesQuery,
} = chatApi;
