/* eslint-disable @typescript-eslint/no-unused-vars */
import { RootState } from "@/Store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      import.meta.env.VITE_REACT_APP_BACKEND_URL || "http://localhost:3333/api",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (_builder) => ({}),
});

export default baseApi;
