import { Button } from '@/ui/button';
import React from 'react';
import { Props } from './state-machine/courier-setup-machine';
import BackButtonIcon from '@/components/delivery-form/components/back-button-icon';
import { useNavigate } from 'react-router-dom';

const SetupInfo: React.FC<Props> = ({ send }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="h-screen p-4">
      <div className="col-span-3 flex gap-3">
        <BackButtonIcon onClick={() => navigate(-1)} />
        <h4 className="mb-10 border-gray-300 pb-4 text-3xl font-medium">Setup Pailot Info</h4>
      </div>
      <p className="mb-6">
        To be a courier on Pailot, you are required to setup few details your hirers will be able to
        distinguish you from other couriers. We advice you do not give your private information to
        the app or user.
      </p>
      <h4 className="mb-6 text-xl font-medium">Get Started</h4>
      <ul className="mb-14 ml-6 flex-grow list-disc opacity-60 ">
        <li>
          <p>
            Show your mode of delivery(s) and region (area, city, LGA, or country) you will operating
            within
          </p>
        </li>
        <li>
          <p>Stake some Pi for insurance for delivery</p>
        </li>
      </ul>
      <Button
        className="mt-8 flex w-full items-center justify-center border-2 border-pi-primary-default text-lg"
        onClick={() => send({ type: 'NEXT' })}
        size="large"
      >
        Get Started
      </Button>
    </div>
  );
};

export default SetupInfo;
