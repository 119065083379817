import Paragraph from "@/components/custom/paragraph";

export default function PrivacyPolicy() {
  return (
    <div>
      <h4 className="is-h4 bg-white mb-8 px-5 py-4 font-medium shadow">
        Privacy Policy
      </h4>
      <div className="mx-3">
        <Paragraph title="">
          We at Pailot Digital Services Limited respect your concerns about
          privacy. This Privacy Notice describes the types of personal
          information we collect about users, how we make use of the information
          and with whom we may share it.it also describes measures we take to
          protect personal information. We also tell you how you can ask us to
          <ul className="list-disc">
            <li>
              Access/Change the personal information we preserve about you
            </li>
            <li>Withdraw consent you previously provided to us </li>
            <li>Cease sending you certain communications and </li>
            <li>Answer questions you may have about our privacy practices </li>
          </ul>
        </Paragraph>
        <Paragraph title="">
          Our privacy practices may vary amidst countries in which we operate to
          comply with local practices and legal requirements.
        </Paragraph>
        <Paragraph title="INFORMATION PAILOT COLLECTS ">
          We may collect user personal information such as name, contact and
          payment information in connection with various user options such as
          <ul className="list-disc">
            <li>Use of Pailot Services websites and applications</li>
            <li>
              Shipping activities including delivery and collection of shipments
            </li>
            <li>
              Shipping activities including delivery and collection of shipments
            </li>
            <li>Promotions and other offers </li>
          </ul>
        </Paragraph>
        <Paragraph title="">
          The types of personal information we collect include:
          <br></br>
          <br></br>
          <ul className="list-disc">
            <li>
              Individual and business contact information such as name, company
              name, physical address and telephone number{" "}
            </li>
            <li>
              Shipping information such as (i) senders contact, consignee’s
              name, physical address and telephone number, (ii) Signature for
              proof of delivery and receipt, (iii) Pailot Service’s account
              details, and (iv) information given to us to enable us access
              locations to which we provide service, as well as information
              given to us regarding the content of certain shipments{" "}
            </li>
            <li>
              Information that enable us identify an individual’s identity{" "}
            </li>
            <li>
              Username, password and other credentials used to access Pailot
              products and services
            </li>
            <li>
              The geographic location of your mobile device if you use certain
              features of our apps Payment information
            </li>
            <li>
              Tax Identification Number in circumstances where requested
              products or services requires it.
            </li>
          </ul>
        </Paragraph>
        <Paragraph title="">
          We also receive collect information from our customers in order to
          perform services. We may collect personal information from third
          parties, including public databases, social media platforms, or third
          party partners such as analytics or marketing providers.When we pick
          up or deliver a shipment or provide other services, we may obtain
          physical location data. This may include data identifying the actual
          location of a physical address using information such as GPS data,
          geocodes, latitude and longitude information, and images of the
          various locations.
        </Paragraph>
        <Paragraph>
          Furthermore, When you visit our websites, use our apps, or interact
          with UPS-related tools, widgets or plug-ins, we may collect certain
          information by automated means such as cookies and web beacons. The
          information we may collect in this manner include IP address, unique
          device identifier, device characteristics, browser characteristics,
          operating system, language preferences, referring URLs, information on
          actions taken, and dates and time of activity. Through the automated
          collection methods, we obtain and store “clickstream” data to tell us
          usage patterns. We may link certain data elements we have collected
          through automated means such as your browser information, with other
          information we have collected about you to let us know, for instance,
          whether you have opened an email we sent to you.
        </Paragraph>
        <Paragraph>
          We may also use third party analytics tool that collect information
          about visitor traffic on our websites or apps. Your browser may tell
          you how to be notified when you receive certain types of cookies or
          how to restrict or disable certain types of cookies. However, without
          cookies you may not be able to use all the features of our websites or
          apps. Others, such as our advertising networks and us may collect
          personal information about our visitors’ online activities, over time
          and across third party apps. The provider of third party apps, tools,
          widgets and plug-ins on our websites, such as Facebook “Like” button,
          may also use automated means to collect information regarding your
          interactions with these features. Such information is subject to the
          privacy policies or notices of these providers.
        </Paragraph>
        <Paragraph title="HOW PAILOT USES THE INFORMATION COLLECTED">
          Pailot may use the information to:
          <ul className="list-disc">
            <li>Pick up, deliver and track shipment</li>
            <li>
              Provide products and services you request such as logistics,
              supply chain management, customs clearance and brokerage services
              and financial services
            </li>
            <li>Process and collect payments </li>
            <li>
              Provide customer support and respond to and communicate with you
              about your requests, questions and comments
            </li>
            <li>
              Send you tracking updates (from Pailot and/or our business
              partners) and help you select convenient delivery options
            </li>
            <li>Establish and manage your Pailot accoun</li>
            <li>
              Offer you products and services that we believe may be of interest
              to you{" "}
            </li>
            <li>
              Communicate about and administer your participation in surveys,
              programmes, special events, contests, prize draws and other offers
              or promotions.
            </li>
            <li>
              Enable you post on our blogs and interact with Pailot through
              social media
            </li>
            <li>Send information to your contacts if you ask us to do so</li>
            <li>Process claims we receive in connection with our service</li>
            <li>
              Operate, evaluate and improve our business (including developing
              new products and services; managing our communications;
              determining the effectiveness of our sales, marketing and
              advertising; analyzing and enhancing our products, services
              websites and apps; ensuring the security of our networks and
              information systems; performing accounting, auditing, invoicing,
              reconciliation and collection activities; and reviewing, improving
              and maintaining the quality of our customer services)
            </li>
            <li>
              Perform data analyses (including market and customer search and
              analytics, trends analysis and profiling, financial analysis and
              anonymization of personal information) Protect against, identify
              and prevent fraud and other prohibited or illegal activity, claims
              and other liabilities
            </li>
            <li>Comply with applicable legal requirements and our policies </li>
            <li>Establish, exercise and defend legal claims </li>
            <li>Monitor and report compliance issues.</li>
          </ul>
        </Paragraph>
        <Paragraph>
          In addition, we use information collected online through cookies, web
          beacons and other automated means for purposes such as customizing our
          users’ visits to our websites and apps, delivering content tailored to
          our users’ interests and the manner in which our users browse our
          websites and apps, and managing our business. We may supplement data
          we collect through automated means with information about your
          location (such as your postcode) to provide you with content that may
          be of interest to you. We also use this information to help diagnose
          technical and service problems, administer our websites and apps,
          identify users of our websites and apps, and gather demographic
          information about our users.{" "}
        </Paragraph>
        <Paragraph title="INFORMATION WE SHARE ">
          We do not sell or share personal information about you, except as
          described in this Privacy Notice. To perform our collection and
          delivery services, we share shipping information with third parties
          such as shippers, consignees, third party payers and recipients. We
          also share personal information with third parties who perform
          services on our behalf based on our instructions. These third parties
          are not authorized by us to use or disclose the information except as
          necessary to perform services on our behalf or comply with legal
          requirements.
        </Paragraph>
        <Paragraph>
          We may also share personal information we obtain with our affiliates,
          franchisees, resellers and joint marketing partners. These entities
          which collectively are referred to as the “Pailot Business Partners”
          may use the information for the purposes described in this Privacy
          Notice. We may share physical location data with our Pailot Business
          Partners and other third parties to, for instance enhance
          location-based services and develop up-to-date maps. In addition,
          except as described below, unless you object we may share other
          personal information with third parties who are not Pailot Business
          Partners for those parties’ own purposes, such as to offer products
          and services that may interest you. Information collected through
          third party apps, tools, widgets and plug-ins (such as information
          obtained through third party login services or relating to your use of
          Facebook “Like” button) is collected directly by the providers of
          these features. This information is subject to the privacy policies of
          the providers of the features, and Pailot is not responsible for those
          providers’ information practices. Many of our customers outsource all
          or part of their supply chain to us.
        </Paragraph>
        <Paragraph>
          Through our forwarding and logistics business units, we manage these
          supply chains, focusing on supply chain optimization, freight
          forwarding and international trade and brokerage services for our
          customers worldwide (including a broad range of shipping solutions
          such as air, ocean and ground freight). We also provide information
          technology systems and distribution facilities adapted to the unique
          supply chains of specific industries such as healthcare, technology
          and consumer/retail businesses. In the course of providing forwarding
          and logistics service, we may obtain, use and disclose personal
          information about our customers’ customers. In this circumstance, we
          process the information based on the agreement with our customer.
        </Paragraph>
        <Paragraph>
          We also may disclose personal information about you if; We are
          required to do so by law, regulation or legal process (such as a court
          order or subpoena) In response to requests by government agencies,
          such as law enforcement authorities, or When we believe disclosure is
          necessary or appropriate to prevent physical harm or financial loss or
          in connection with an investigation of suspected or actual illegal
          activity. We reserve the right to transfer any information we have
          about you in the event that we sell all or a portion of our business
          or assets (including the event of a reorganization, dissolution or
          liquidation).
        </Paragraph>
        <Paragraph title="YOUR OPTIONS ">
          We offer you certain choices about how we communicate with you and
          what information we collect from you. You may chose not to receive
          marketing email communications from us by clicking on the unsubscribe
          link in our marketing emails, selecting the Email Unsubscribe link,
          adjusting your email preference in your pailot.app profile, or
          contacting us as specified in the “How To Contact Us” section. You may
          ask us not to send you other marketing communications by contacting
          us, and we will honor your request. In line with the choices available
          in “information We Share” section, you may withdraw your consent or
          object to certain information sharing by contacting us. Additionally,
          as required by the law you may object at any time on legitimate
          grounds and free of charge to the processing of your personal
          information, and we will apply your preferences going forward.
        </Paragraph>
        <Paragraph title="ACCESS AND CORRECTIONS ">
          Subject to applicable law you may; Obtain a copy of certain personal
          information we maintain about yoi or update or correct inaccuracies in
          that information through your pailot.app account or Have the right to
          obtain access to personal information we maintain about you by
          contacting us. To help protect your privacy and maintain security, we
          will employ security measures (such as ask you to provide certain
          details) before granting you access to the information. In addition,
          if you believe that personal information we maintain about you is
          inaccurate, subject to applicable law you may have the right to
          request that we amend the information by contacting us.
        </Paragraph>
        <Paragraph title="DATA RETENTION ">
          Your personal information will not be stored for longer than necessary
          for the purposes for which they were collected or as required under
          applicable retention policies and/or in accordance with applicable
          law. Our retention policies reflect local statute of limitation
          periods and national statutory obligations of Pailot.{" "}
        </Paragraph>
        <Paragraph title="DATA TRANSFERS ">
          We may transfer personal information we collect about you to countries
          other than the country in which the information originally was
          collected. Those countries may not have the same data protection laws
          as the country in which you initially provided the information.
        </Paragraph>
        <Paragraph>
          When we transfer your personal information to other countries, we will
          protect that information as described in this Privacy Notice and in
          accordance with applicable law. We use contractual protections for the
          transfer of personal information among various jurisdictions
          (including, for instance, the European Commission’s Standard
          contractual clauses).
        </Paragraph>
        <Paragraph title="HOW WE PROTECT PERSONAL INFORMATION ">
          We maintain administrative, technical and physical security designed
          to protect the personal information you provide against accidental,
          unlawful and unauthorized destruction, loss, alteration, access,
          disclosure or use. Although we take steps to limit access to our
          facilities and vehicles to authorized individuals, information that is
          located on the outside of a parcel or letter may be visible to others.
        </Paragraph>
        <Paragraph title="HOW TO CONTACT US ">
          If you have any questions and comments about this Privacy notice, or
          if you would like us to update information we have about you or your
          preferences, please contact in any of our social media handle on
          Twitter, Facebook, Linkedin, Discord and Telegram{" "}
          <em>@PailotServices</em>.
        </Paragraph>
        <Paragraph title="UPDATES TO OUR PRIVACY NOTICE ">
          This Privacy Notice may be updated periodically and without prior
          notice to you to reflect changes in our personal information
          practices. We will post a prominent notice on our websites to notify
          you of any significant changes to our Privacy Notice and indicate at
          the top of the notice when it was most recently updated.{" "}
        </Paragraph>
      </div>
    </div>
  );
}
