import { assign, createMachine } from "xstate";

export type Context = {
  step: number;
  formData: {
    stakingAmount: number;
    preferredModeOfDelivery: string;
    modesOfDelivery: string[];
    preferredPrice: number;
    country: string;
    state: string;
    city: string;
  };
};

export type Events =
  | { type: "NEXT" }
  | { type: "PREVIOUS" }
  | { type: "UPDATE"; value: Context };

type States =
  | { value: "step1"; context: Context }
  | { value: "step2"; context: Context }
  | { value: "step3"; context: Context }
  | { value: "submit"; context: Context }
  | { value: "success"; context: Context };

export type Props = {
  state: { context: Context };
  send: (_event: Events) => void;
};

const initialContext: Context = {
  step: 1,
  formData: {
    stakingAmount: 0,
    preferredModeOfDelivery: "",
    modesOfDelivery: [],
    preferredPrice: 0,
    country: "",
    state: "",
    city: "",
  },
};
// @ts-expect-error - XState types are not compatible with the machine
export const courierSetupFormMachine = createMachine<Context, Events, States>({
  id: "CourierSetupForm",
  initial: "step1",
  context: initialContext,
  on: {
    UPDATE: {
      actions: assign((_, event: any) => {
        return event.value;
      }),
    },
  },
  states: {
    step1: {
      on: {
        NEXT: {
          target: "step2",
          actions: assign((state) => ({
            ...state,
            step: state.context.step + 1,
          })),
        },
      },
    },
    step2: {
      on: {
        NEXT: {
          target: "step3",
          actions: assign((state) => ({
            ...state,
            step: state.context.step + 1,
          })),
        },
        PREVIOUS: {
          target: "step1",
          actions: assign((state) => ({
            ...state,
            step: state.context.step - 1,
          })),
        },
      },
    },
    step3: {
      on: {
        NEXT: {
          target: "submit",
          actions: assign((state) => ({
            ...state,
            step: state.context.step + 1,
          })),
        },
        PREVIOUS: {
          target: "step2",
          actions: assign((state) => ({
            ...state,
            step: state.context.step - 1,
          })),
        },
      },
    },
    submit: {
      on: {
        // NEXT: "success",
        PREVIOUS: {
          target: "step3",
          actions: assign((state) => ({
            ...state,
            step: state.context.step - 1,
          })),
        },
      },
    },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
});
