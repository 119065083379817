import { Button } from '@/ui/button';
import { ChevronLeft } from 'lucide-react';

function BackButtonIcon({ onClick }: { onClick: () => void }) {
  return (
    <Button className="min-w-9 px-0 py-0" fill="ghost" onClick={onClick}>
      <div className="h-9 w-9 rounded-full bg-pi-primary-light">
        <ChevronLeft color="#1E04BB" size={36} strokeWidth={1} />
      </div>
    </Button>
  );
}

export default BackButtonIcon;
