import { SocketContext } from "@/contexts/socket-context";
import { useContext } from "react";

export function useSystemChatMessage() {
  const { socket, isReady } = useContext(SocketContext);

  async function sendSystemMessage({
    chatId,
    content,
    file,
  }: {
    chatId: string;
    content: string;
    file?: File;
  }) {
    if (!isReady || !socket) {
      return;
    }

    try {
      socket.emit("systemMessage", { chatId, content, file });
    } catch (error) {
      console.error("Error sending system message:", error);
    }
  }

  return { sendSystemMessage };
}
