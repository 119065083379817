import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useMachine } from '@xstate/react';
import { courierSetupFormMachine } from './state-machine/courier-setup-machine';
import { Loader } from '@/components';
import SetupInfo from './setup-info';
import SetupOptions from './setup-options';
import CourierPriceForm from './courier-price-form';
import {
  useApproveStakePaymentMutation,
  useCompleteStakePaymentMutation,
  useCancelledStakePaymentMutation,
} from '@/services/stake.services';
import { useNavigate } from 'react-router-dom';
import DeliveryStaking from './delivery-staking';
import { setCourierProfile } from '@/Store/Features/courierSlice';
import { toast } from 'react-hot-toast';
import ToastedAlert from '@/components/custom/ToastedAlert';
import { useDispatch } from 'react-redux';

export type CourierSetupForm = {
  stakingAmount: number;
  preferredModeOfDelivery: string;
  modesOfDelivery: string[];
  preferredPrice: number;
  country: string;
  state: string;
  city: string;
};

function SetupForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, send] = useMachine(courierSetupFormMachine);
  const [approveStakePayment, { isLoading: isApproving }] = useApproveStakePaymentMutation();
  const [completeStakePayment, { isLoading: isCompleting }] = useCompleteStakePaymentMutation();
  const [cancelStakePayment, { isLoading: isCanceling }] = useCancelledStakePaymentMutation();
  const form = useForm<CourierSetupForm, any, CourierSetupForm>({
    defaultValues: {
      stakingAmount: 0,
      preferredModeOfDelivery: '',
      modesOfDelivery: [],
      preferredPrice: 0,
      country: '',
      state: '',
      city: '',
    },
  });

  const handleStakePayment = async (data: CourierSetupForm) => {
    try {
      const onReadyForServerApproval = async (paymentId: string) => {
        console.log('onReadyForServerApproval', paymentId);
        const courier = await approveStakePayment({
          paymentId,
        }).unwrap();
        dispatch(setCourierProfile(courier));
      };

      const onReadyForServerCompletion = async (paymentId: string, txid: string) => {
        console.log('onReadyForServerCompletion', paymentId, txid);
        await completeStakePayment({
          paymentId,
          txid,
          courierData: {
            country: data.country,
            state: data.state,
            city: data.city,
            stakeAmount: data.stakingAmount,
            preferredModeOfDelivery: data.preferredModeOfDelivery,
            modesOfDelivery: [
              ...new Set([...data.modesOfDelivery, data.preferredModeOfDelivery]),
            ].join(','),
            preferredDeliveryAmount: data.preferredPrice,
          },
        }).unwrap();
        navigate('/success', { state: { mode: 'staking' } });
      };

      const onCancel = async (paymentId: string) => {
        console.log('onCancel', paymentId);
        return await cancelStakePayment({ paymentId }).unwrap();
      };

      const onError = (error: any) => {
        console.log('onError', error);
      };

      const paymentData = {
        amount: data.stakingAmount,
        memo: 'Pailot Stake',
        metadata: {
          amount: data.stakingAmount,
        },
      };

      const callbacks = {
        onReadyForServerApproval,
        onReadyForServerCompletion,
        onCancel,
        onError,
      };

      return await window.Pi.createPayment(paymentData, callbacks);
    } catch (error) {
      console.log(error);
      throw new Error('An unexpected error occurred while processing payment');
    }
  };

  const onSubmit: SubmitHandler<CourierSetupForm> = async (data: CourierSetupForm) => {
    try {
      if (data.preferredPrice && data.preferredPrice <= 0) {
        toast.error(
          <ToastedAlert description="Your preferred delivery price cannot be zero" title="Error" />
        );
        return;
      }
      const payment = await handleStakePayment(data);
      console.info(payment, 'payment');
    } catch (error) {
      console.error(error);
      toast.error(
        <ToastedAlert
          description="An error occurred while creating the courier profile, Try again!"
          title="Error"
        />
      );
    }
  };

  if (isApproving || isCompleting || isCanceling) return <Loader />;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(event) => {
          // We need to clear the errors because react hook form will refuse to submit the form if there are errors
          form.clearErrors();
          event.stopPropagation();
          return form.handleSubmit(async (data) => await onSubmit(data))(event);
        }}
      >
        {state.matches('step1') && <SetupInfo send={send} state={state} />}
        {state.matches('step2') && <SetupOptions form={form} send={send} state={state} />}
        {state.matches('step3') && <CourierPriceForm form={form} send={send} state={state} />}
        {state.matches('submit') && <DeliveryStaking form={form} send={send} state={state} />}
      </form>
    </FormProvider>
  );
}

export default SetupForm;
