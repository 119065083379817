import React from 'react';
import { cn } from '@/lib/utils';

interface FooterProps {
  className?: string;
  children?: React.ReactNode;
}

const Footer = React.forwardRef<HTMLElement, FooterProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <footer ref={ref} className={cn('h-auto w-full', className)} {...props}>
        {children}
      </footer>
    );
  }
);

export { Footer };
