import { useState } from 'react';
import { ChevronLeft, ChevronRight, CheckCircle2, Rocket } from 'lucide-react';

import { StickyNote } from 'lucide-react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@/ui/button';
import AcceptedDeliveries from './components/bookings/accepted-deliveries';
import SuccessfulDeliveries from './components/bookings/successful-deliveries';
import { useGetDeliveriesByCourierQuery,  } from '@/services/delivery.services';
import { Loader } from '@/components';
import {  calculatePercentage, successfulDeliveriesHandler } from '@/utilities/helperFunctions';
import { DeliveryStatus } from '@/types/pailot-interfaces';

const CourierBookings = () => {
  const navigate = useNavigate();
  const [deliverySection, setDeliverySection] = useState<
    'accepted' | 'successful' | 'failed' | null
  >(null);
  const { 
    data: deliveries, 
    error, 
    isLoading 
  } = useGetDeliveriesByCourierQuery();

  const goBack = () => {
    if (deliverySection === null) {
      navigate(-1);
    } else {
      setDeliverySection(null);
    }
  };


  if (error && !isLoading && !deliveries) {
    goBack();
  }

 

  // const acceptedDeliveries = acceptedDeliveriesHandler(deliveries ? deliveries : []);
  const acceptedDeliveries = deliveries ? deliveries.filter(
    (delivery) =>
      delivery.deliveryStatus !== DeliveryStatus.CREATED &&
      delivery.deliveryStatus !== DeliveryStatus.CANCELLED &&
      delivery.deliveryStatus !== DeliveryStatus.REJECTED &&
      delivery.deliveryStatus !== DeliveryStatus.DELIVERED
  ) : [];
  const successfulDeliveries = successfulDeliveriesHandler(deliveries ? deliveries : []);

  return (
    <>
    {
      (deliveries && !isLoading && !error) ? (<div className="flex h-screen flex-col">
        <button
          className="flex w-full items-center space-x-1 bg-pi-primary-default px-5 py-3 text-base-white"
          onClick={() => goBack()}
          type="button"
        >
          <ChevronLeft />
          {deliverySection === null && <span>Bookings</span>}
          {deliverySection === 'accepted' && <span>Accepted Deliveries</span>}
          {deliverySection === 'successful' && <span>Successful Deliveries</span>}
          {/* {deliverySection === 'failed' && <span>Failed Deliveries</span>} */}
        </button>
        {deliverySection === 'accepted' && <AcceptedDeliveries deliveries={acceptedDeliveries}  />}
        {deliverySection === 'successful' && <SuccessfulDeliveries deliveries={successfulDeliveries} successRate={calculatePercentage(successfulDeliveries.length, deliveries.length)}/>}
        {/* {deliverySection === 'failed' && <FailedDeliveries />} */}
        {deliverySection === null && (
          <>
            {deliveries.length === 0 ? (
              <div
                className=" flex w-full
        flex-1 flex-col items-center justify-center "
              >
                <div
                  className="
        flex flex-1 flex-col items-center justify-center p-4
        "
                >
                  <div className="flex flex-col items-center">
                    <StickyNote size={50} />
                    <h4 className="mb-4 mt-6 opacity-80">You have no accepted delivery</h4>
                    <p className="mb-4 text-center text-xs font-semibold opacity-60">
                      See pioneers requesting for delivery in your region
                    </p>
                    <Button
                      className="flex w-full items-center justify-center gap-1 whitespace-nowrap border-pi-primary-default text-base-white"
                      onClick={() => {
                        navigate('/courier');
                      }}
                    >
                      Pickup a delivery
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className=" flex w-full
        flex-1 flex-col gap-4 p-4"
              >
                <button
                  className="flex items-center justify-between gap-4 rounded bg-base-white p-4"
                  onClick={() => {
                    if (acceptedDeliveries?.length > 0) {
                       setDeliverySection('accepted')
                    }
                  }}
                  type="button"
                >
                  <Rocket size={22} />
                  <div className="flex flex-1 flex-col items-start">
                    <h4 className="text-base">Accepted deliveries</h4>
                    <p className="text-xs text-left">Urgent deliveries from your pickups</p>
                  </div>
                  <span
                    className="flex h-[25px] w-[25px] 
                items-center justify-center rounded-full   bg-[var(--secondary-dark)] text-base-white
                "
                  >
                    {acceptedDeliveries.length}
                  </span>
                  <ChevronRight />
                </button>
                <button
                  className="flex items-center justify-between gap-4 rounded bg-base-white p-4"
                  onClick={() => {
                    if (successfulDeliveries?.length > 0) {
                       setDeliverySection('successful')
                    }
                  }}
                  type="button"
                >
                  <CheckCircle2 size={22} />
                  <div className="flex flex-1 flex-col items-start">
                    <h4 className="text-base">Successful deliveries</h4>
                    <p className="text-xs text-left">Delivered products, goods or services</p>
                  </div>
                  <span
                    className="flex h-[25px] w-[25px] 
                items-center justify-center rounded-full   bg-[var(--success-60)] text-base-white
                "
                  >
                    {successfulDeliveries.length}
                  </span>
                  <ChevronRight />
                </button>
  
              </div>
            )}
          </>
        )}
      </div>) : (
        <Loader />
      )
    }
    </>
  );
};

export default CourierBookings;
