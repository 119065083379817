import { Delivery } from "@/services/delivery.services";
import { Review } from "@/services/review.services";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  userUid: string;
  username: string;
  walletAddress: string | null;
  profileImgUrl: string | null;
  accessToken: string;
  activeProfile: {
    name: "sender" | "courier";
  };
  createdAt: string;
}

export type SenderProfile = {
  id: string;
  user: User;
};

export type CourierProfile = {
  id: string;
  user: User;
  isActive: boolean;
  modesOfDelivery: string;
  preferredModeOfDelivery: string;
  country: string;
  state: string;
  city: string;
  preferredDeliveryAmount: number;
  earnings: number;
  stakeAmount: number;
  deliveries: Delivery[];
  reviews: Review[];
};

export type ReceiverProfile = {
  id: string;
  user: User;
};

export interface AuthState {
  user: User | null;
  accessToken: string;
  userAddress?: {
    city: string;
    country: string;
  };
}

const initialState: AuthState = {
  user: null,
  accessToken: localStorage.getItem("accessToken") || "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<AuthState>) {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },

    updateUserAddress(
      state,
      action: PayloadAction<{
        city: string;
        country: string;
      }>,
    ) {
      state.userAddress = action.payload;
    },
  },
});

export const { setCredentials, updateUser, updateUserAddress } =
  authSlice.actions;
export default authSlice.reducer;
