import { cn } from '@/lib/utils';
import { ReactNode, ComponentType } from 'react';
import { IconBaseProps } from 'react-icons/lib';

export default function EmptyMessage({
  children,
  className,
  message,
  icon: Icon,
}: {
  children: ReactNode[] | ReactNode;
  className?: string;
  message: string;
  icon: ComponentType<IconBaseProps>;
}) {
  return (
    <div
      className={cn(
        ' flex flex-col items-center justify-center gap-8 px-5 text-stone-600',
        className
      )}
    >
      <div>
        <h5>{message}</h5>
      </div>
      <Icon size="60px" />
      {children}
    </div>
  );
}
