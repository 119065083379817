import React from 'react';
import { SheetContent, SheetHeader, SheetTitle, SheetDescription, SheetClose } from '@/ui/sheet';
import { Button } from '@/ui/button';
import { motion } from 'framer-motion';

interface VcodeSheetStatusContentProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  iconBg: string;
  primaryActionText?: string;
  onPrimaryAction?: () => void;
  primaryActionDisabled?: boolean;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
  secondaryActionDisabled?: boolean;
  images?: { src: string; alt: string }[];
  isClosable?: boolean;
}

const VcodeSheetStatusContent: React.FC<VcodeSheetStatusContentProps> = ({
  title,
  description,
  icon,
  iconBg,
  primaryActionText,
  onPrimaryAction,
  primaryActionDisabled,
  secondaryActionText,
  onSecondaryAction,
  secondaryActionDisabled,
  images,
  isClosable = false,
}) => {
  return (
    <SheetContent
      className="flex flex-col items-center rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura"
      side="bottom"
    >
      <SheetHeader>
        <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
        <SheetTitle className="text-medium font-futura font-medium">{title}</SheetTitle>
      </SheetHeader>

      <div className="flex items-center justify-center">
        <div
          className={`flex aspect-square w-[20vw] items-center justify-center rounded-full ${iconBg}`}
        >
          {icon}
        </div>
      </div>

      <SheetDescription className="mt-5 text-center text-lg font-extralight text-base-black opacity-80">
        {description}
      </SheetDescription>

      {images && images.length > 0 && (
        <motion.div
          className="relative flex h-[50px] w-[90px] items-center self-center"
          initial={{ width: '50%', opacity: 0 }}
          whileInView={{ width: 90, opacity: 1 }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              alt={image.alt}
              src={image.src}
              className={`absolute ${
                index === 0 ? 'left-0' : 'right-0'
              } aspect-square w-[50px] rounded-full bg-zinc-300`}
            />
          ))}
        </motion.div>
      )}

      <div className="w-full">
        {onPrimaryAction && (
          <Button
            className="mt-2 w-full text-base-white"
            onClick={onPrimaryAction}
            disabled={primaryActionDisabled}
          >
            {primaryActionText}
          </Button>
        )}
        {onSecondaryAction && (
          <Button
            className="mt-4 w-full text-pi-primary-default"
            onClick={onSecondaryAction}
            disabled={secondaryActionDisabled}
            fill="outline"
          >
            {secondaryActionText}
          </Button>
        )}
      </div>

      {isClosable && (
        <SheetClose className="w-full">
          <Button className="mt-2 w-full text-base-white" fill="default">
            Close
          </Button>
        </SheetClose>
      )}
    </SheetContent>
  );
};

export default VcodeSheetStatusContent;
