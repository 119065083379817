import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import FormHeader from './form-header';
import { Props } from '../state-machine/state-machine';
import { Button } from '@/ui/button';
import { DeliveryFormData } from '../delivery-form';
import { UseFormReturn } from 'react-hook-form';
import GooglePlacesAutoComplete from './google-places-autocomplete';
import DeliveryMode from './delivery-mode';
import { Checkbox } from '@/ui/checkbox';
import { useState } from 'react';

function DeliveryLocationForm({
  form,
  send,
  state,
  hasCourierDeliveryModes,
}: Props & {
  form: UseFormReturn<DeliveryFormData, any, DeliveryFormData>;
  hasCourierDeliveryModes: boolean;
}) {
  const {
    formState: { isValid, errors },
    register,
  } = form;

  const [isDropOffAddressChosen, setIsDropOffAddressChosen] = useState<boolean>(false);
  const [isPickupAddressChosen, setIsPickupAddressChosen] = useState<boolean>(false);

  const [MODsChosen, setMODsChosen] = useState<string[]>([]);

  return (
    <div className="flex flex-1 flex-col justify-between gap-4 overflow-auto px-4 pb-4 w-full">
      <div className="flex-1 ">
        <FormHeader
          description="Input locations and select your required mode of delivery."
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Location & Delivery Mode"
        />
        <div className="flex flex-col gap-4 sm:pt-4">
          <FormField
            name="pickupAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Pickup location</FormLabel>
                <FormControl>
                  <GooglePlacesAutoComplete
                    {...register('pickupAddress', {
                      required: 'Pickup location is required',
                    })}
                    invalid={!!errors.pickupAddress}
                    onAddressSelected={(addressComponents) => console.log(addressComponents)}
                    placeholder="e.g Ajah"
                    stateUpdate={setIsPickupAddressChosen}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="dropOffAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Drop-off location</FormLabel>
                <FormControl>
                  <GooglePlacesAutoComplete
                    {...register('dropOffAddress', {
                      required: 'Drop-off location is required',
                    })}
                    invalid={!!errors.dropOffAddress}
                    onAddressSelected={(addressComponents) => console.log(addressComponents)}
                    placeholder="e.g Lekki phase 1"
                    stateUpdate={setIsDropOffAddressChosen}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!hasCourierDeliveryModes && <DeliveryMode stateUpdate={setMODsChosen} />}
          <div className="flex flex-col gap-5 ">
            <h5 className="normal-case">Safety Checklist</h5>
            <FormField
              name="isItemPackaged"
              render={({ field }) => (
                <FormItem className="flex items-center gap-3">
                  <FormControl>
                    <Checkbox
                      {...register('isItemPackaged', {
                        required: 'This is required',
                      })}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Is the item well packaged?</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="isItemSealed"
              render={({ field }) => (
                <FormItem className="flex items-center gap-3">
                  <FormControl>
                    <Checkbox
                      {...register('isItemSealed', {
                        required: 'This is required',
                      })}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Is the item properly sealed and wrapped?</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
      <Button
        disabled={
          !isValid ||
          isPickupAddressChosen !== true ||
          isDropOffAddressChosen !== true ||
          (!hasCourierDeliveryModes && MODsChosen.length === 0)
        }
        onClick={() => send({ type: 'NEXT' })}
        size="large"
      >
        Next
      </Button>
    </div>
  );
}

export default DeliveryLocationForm;
