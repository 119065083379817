import { lazy } from "react";
export const OnBoarding = lazy(() => import("./onboarding/onboarding"));
export const WelcomePage = lazy(() => import("./welcom-page/welcome-page"));
export const ProfilePage = lazy(() => import("./profile-page/profile-page"));
export const SederPage = lazy(() => import("./sender-page/sender-page"));
export const AllChat = lazy(() => import("./chat-page/all-chats"));
export const ChatScreen = lazy(() => import("./chat-page/chat-screen"));

export const SenderHomepage = lazy(
  () => import("./sender-page/sender-homepage"),
);

export const ReceiverPage = lazy(() => import("./receiver-page/receiver-page"));
export const ReceiverTimeline = lazy(
  () => import("./receiver-page/receiver-timeline"),
);
export const ReceiverDelivery = lazy(
  () => import("./receiver-page/receiver-delivery"),
);
export const ReceiverPackage = lazy(
  () => import("./receiver-page/receiver-package"),
);

export const CourierInfo = lazy(() => import("./sender-page/courier-info"));

export const CourierPage = lazy(() => import("./courier-pages/courier-page"));
export const CourierHomepage = lazy(
  () => import("./courier-pages/courier-homepage"),
);
export const MyRequestDetails = lazy(
  () => import("./courier-pages/my-request-details"),
);

export const CourierDashboard = lazy(
  () => import("./courier-pages/courier-dashboard"),
);
export const WalletSettings = lazy(
  () => import("./courier-pages/wallet-settings"),
);
