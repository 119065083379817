function LocalStorageServices() {
  const setAccessToken = (token: string) =>
    localStorage.setItem("accessToken", token);
  const getAccessToken = () => localStorage.getItem("accessToken");

  const getUserOnboarded = () => localStorage.getItem("userOnboarded");
  const setUserOnboarded = (value: string) =>
    localStorage.setItem("userOnboarded", value);

  const getUserRole = () => localStorage.getItem("userRole");
  const setUserRole = (value: string) =>
    localStorage.setItem("userRole", value);

  const setUserDetails = (userDetails: Record<string, string | number>) =>
    localStorage.setItem("user_details", JSON.stringify(userDetails));
  const getUserDetails = () =>
    JSON.parse(localStorage.getItem("user_details") || "{}");

  const deleteAllStorage = () => {
    localStorage.clear();
  };
  return {
    setAccessToken,
    getAccessToken,
    getUserOnboarded,
    setUserOnboarded,
    getUserRole,
    setUserRole,
    setUserDetails,
    getUserDetails,
    deleteAllStorage,
  };
}
export default LocalStorageServices;
