import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Features/authSlice';
import courierSlice from './Features/courierSlice';
import baseApi from '@/services/base.services';
import chatSlice from './Features/chatSlice';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authSlice,
    courier: courierSlice,
    chat: chatSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
