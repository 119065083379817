import { Pi } from "lucide-react";
import { ReactNode } from "react";

function InputWithPiIcon({ children }: { children: ReactNode }) {
  return (
    <div className="placeholder:text-primary-hint ring-offset-background text-md mt-1 flex h-11 w-full items-center rounded-md border border-input-border text-input-content file:border-0 file:bg-transparent file:text-sm file:font-medium focus:border-input-border-active focus:bg-input-background-active focus:text-input-content-active focus-visible:outline-none disabled:cursor-not-allowed">
      {children}
      <div className="flex h-full items-center rounded bg-pi-primary-light px-3">
        <Pi size="16px" />
      </div>
    </div>
  );
}

export default InputWithPiIcon;
