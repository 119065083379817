import { CourierProfile, ReceiverProfile, SenderProfile } from '@/Store/Features/authSlice';
import baseApi from './base.services';
import {
  GET_DELIVERIES,
  GET_DELIVERY_BY_ID,
  GET_DELIVERY_BY_SENDER,
  GET_DELIVERY_BY_RECEIVER,
  GET_SENDER_DELIVERY_HISTORY,
  GET_DELIVERIES_NEAR_COURIER,
  ACCEPT_DELIVERY,
  GET_COURIER_ACCEPTED_DELIVERIES,
  CREATE_DELIVERY,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_STATUS,
  UPDATE_DELIVERY_PAYMENT,
  VERIFY_RECEIVER_DELIVERY_CODE,
  VERIFY_SENDER_DELIVERY_CODE,
  GET_DElIVERIES_FOR_COUIRER,
  DELETE_DELIVERY,
} from '@/constants/config';
import { Review } from './review.services';

export type Delivery = {
  id: string;
  trackingNumber: string;
  chatId: string;
  courier: CourierProfile;
  senderProfile: SenderProfile;
  receiverProfile: ReceiverProfile;
  preferredModeOfDelivery: string;
  transactionAmount: number;
  fromAddress: string;
  toAddress: string;
  itemImage: string;
  itemName: string;
  itemDescription: string;
  estimatedDeliveryTime: number;
  acceptedDate: string;
  assignedDate: string;
  pickupDate: string;
  returnedDate: string;
  deliveryDate: string;
  deliveryCode: number;
  deliveryStatus: string;
  itemCategory: string;
  pickupCode: number;
  reviews: Review[];
  createdAt: string;
  updatedAt: string;
};

export type DeliveryCreateRequestDto = {
  itemImage: File;
  courierUid?: string;
  receiverUid: string;
  preferredModeOfDelivery: string;
  transactionAmount: number;
  fromAddress: string;
  toAddress: string;
  itemName: string;
  itemDescription: string;
  itemCategory: string;
};

export type UpdateDeliveryType = {
  id: string;
  receiverUid?: string;
  preferredModeOfDelivery?: string;
  transactionAmount?: number;
  fromAddress?: string;
  toAddress?: string;
  itemName?: string;
  itemDescription?: string;
  itemCategory?: string;
};

export const deliveryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveries: builder.query<Delivery[], void>({
      query: () => GET_DELIVERIES,
    }),
    getDeliveriesByCourier: builder.query<Delivery[], void>({
      query: () => GET_DElIVERIES_FOR_COUIRER,
    }),
    getDeliveryById: builder.query<Delivery, { id: string }>({
      query: ({ id }) => GET_DELIVERY_BY_ID(id),
    }),
    getDeliveryBySender: builder.query<Delivery[], { senderUid: string }>({
      query: ({ senderUid }) => GET_DELIVERY_BY_SENDER(senderUid),
    }),
    getDeliveryByReceiver: builder.query<Delivery[], { receiverUid: string }>({
      query: ({ receiverUid }) => GET_DELIVERY_BY_RECEIVER(receiverUid),
    }),
    getSenderDeliveryHistory: builder.query<{ sent: Delivery[]; received: Delivery[] }, void>({
      query: () => GET_SENDER_DELIVERY_HISTORY,
    }),
    getDeliveriesNearCourier: builder.query<Delivery[], void>({
      query: () => GET_DELIVERIES_NEAR_COURIER,
      keepUnusedDataFor: 0,
    }),
    acceptDelivery: builder.mutation<
      Delivery & {
        chatId: string;
      },
      { deliveryId: string }
    >({
      query: ({ deliveryId }) => ({
        url: ACCEPT_DELIVERY(deliveryId),
        method: 'POST',
      }),
    }),
    getCourierAcceptedDeliveries: builder.query<Delivery[], void>({
      query: () => GET_COURIER_ACCEPTED_DELIVERIES,
      keepUnusedDataFor: 0,
    }),
    // Creating a delivery requires a multipart/form-data request so we use FormData as the type for the body
    // The form data should contain the following fields in the DeliveryCreateRequestDto format
    createDelivery: builder.mutation<Delivery, FormData>({
      query: (formData) => ({
        url: CREATE_DELIVERY,
        method: 'POST',
        body: formData,
        formData: true,
      }),
    }),
    updateDelivery: builder.mutation<void, UpdateDeliveryType>({
      query: ({ id, ...rest }) => ({
        url: UPDATE_DELIVERY(id),
        method: 'PUT',
        body: rest,
      }),
    }),
    updateDeliveryStatus: builder.mutation<Delivery, { deliveryId: string; status: string }>({
      query: ({ deliveryId, status }) => ({
        url: UPDATE_DELIVERY_STATUS(deliveryId),
        method: 'PUT',
        body: { status },
      }),
    }),
    updateDeliveryPayment: builder.mutation<void, { deliveryId: string; paymentId: string }>({
      query: ({ deliveryId, paymentId }) => ({
        url: UPDATE_DELIVERY_PAYMENT(deliveryId, paymentId),
        method: 'PUT',
      }),
    }),

    verifySenderDeliveryCode: builder.mutation<
      { status: string; verified: boolean },
      { deliveryId: string; code: number }
    >({
      query: ({ deliveryId, code }) => ({
        url: VERIFY_SENDER_DELIVERY_CODE,
        method: 'POST',
        body: { deliveryId, code },
      }),
    }),

    verifyReceiverDeliveryCode: builder.mutation<
      { status: string; verified: boolean },
      { deliveryId: string; code: number }
    >({
      query: ({ deliveryId, code }) => ({
        url: VERIFY_RECEIVER_DELIVERY_CODE,
        method: 'POST',
        body: { deliveryId, code },
      }),
    }),

    deleteDelivery: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: DELETE_DELIVERY(id),
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDeliveriesQuery,
  useGetDeliveryByIdQuery,
  useGetDeliveryBySenderQuery,
  useGetDeliveryByReceiverQuery,
  useGetSenderDeliveryHistoryQuery,
  useGetDeliveriesNearCourierQuery,
  useAcceptDeliveryMutation,
  useGetCourierAcceptedDeliveriesQuery,
  useCreateDeliveryMutation,
  useUpdateDeliveryMutation,
  useUpdateDeliveryStatusMutation,
  useUpdateDeliveryPaymentMutation,
  useVerifyReceiverDeliveryCodeMutation,
  useVerifySenderDeliveryCodeMutation,
  useGetDeliveriesByCourierQuery,
  useDeleteDeliveryMutation
} = deliveryApi;
