import { Delivery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Select, SelectContent, SelectItem, SelectTriggerIcon, SelectValue } from '@/ui/select';
import { ListFilter } from 'lucide-react';
import { useState } from 'react';

type FilterType = 'all' | 'pending' | 'active' | 'completed' | DeliveryStatus;

export const DeliveryFilter = ({
  deliveries,
  onFilterDeliveries,
}: {
  deliveries: Delivery[];
  onFilterDeliveries: (deliveries: Delivery[]) => void;
}) => {
  const [filter, setFilter] = useState<FilterType>('all');

  const handleFilterChange = (value: FilterType) => {
    setFilter(value);
    if (value === 'all') {
      onFilterDeliveries(deliveries);
    } else if (value === 'pending') {
      const filtered = deliveries?.filter(
        (item) =>
          item.deliveryStatus === DeliveryStatus.CREATED ||
          item.deliveryStatus === DeliveryStatus.ACCEPTED ||
          item.deliveryStatus === DeliveryStatus.ASSIGNED
      );
      onFilterDeliveries(filtered);
    } else if (value === 'active') {
      const filtered = deliveries?.filter(
        (item) =>
          item.deliveryStatus === DeliveryStatus.PICKED_UP ||
          item.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER ||
          item.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER
      );
      onFilterDeliveries(filtered);
    } else if (value == 'completed') {
      const filtered = deliveries?.filter(
        (item) =>
          item.deliveryStatus === DeliveryStatus.DELIVERED ||
          item.deliveryStatus === DeliveryStatus.RETURNED ||
          item.deliveryStatus === DeliveryStatus.CANCELLED ||
          item.deliveryStatus === DeliveryStatus.REJECTED
      );
      onFilterDeliveries(filtered);
    } else {
      const filtered = deliveries?.filter((item) => item.deliveryStatus === value);
      onFilterDeliveries(filtered);
    }
  };

  return (
    <Select onValueChange={handleFilterChange} value={filter}>
      <SelectTriggerIcon>
        <div className="flex items-center gap-2">
          {filter !== 'all' ? <SelectValue className="text-stone-600" /> : null}
          <ListFilter className="text-pi-primary-default" />
        </div>
      </SelectTriggerIcon>
      <SelectContent>
        <SelectItem value="all">All</SelectItem>
        <SelectItem value="pending">Pending</SelectItem>
        <SelectItem value="active">Active</SelectItem>
        <SelectItem value="completed">Completed</SelectItem>
      </SelectContent>
    </Select>
  );
};
