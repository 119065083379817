import { cn } from '@/lib/utils';
import React from 'react';

export default function Tag({
  className,
  children,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <span className={cn(`$ rounded-lg bg-slate-100 px-2 py-0.5 text-stone-500`, className)}>
      {children}
    </span>
  );
}
