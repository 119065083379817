import "./loaderStyle.css";

interface LoaderProps {
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className }) => {
  return (
    <div
      className={` bg-pi-primary/30  fixed flex items-center justify-center ${className ? className : "h-screen w-screen"}`}
    >
      <div className="custom-loader" />
    </div>
  );
};

export default Loader;
