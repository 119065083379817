import { Avatar, AvatarImage } from '@/ui/avatar';
import { Card } from '@/ui/card';
import { Button } from '@/ui/button';
import { ChevronRight, MapPin, Trash2, Pi } from 'lucide-react';
import Dot from '@/components/dot';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import ModesOfDeliveryIcon from './modesOfDeliveryIcon';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';

type SentDeliveryCardProps = {
  imageUrl: string;
  itemName: string;
  deliveryLocation: string;
  receiverImageUrl: string;
  receiverUsername: string;
  deliveryStatus: string;
  price: number;
  deliveryId: string;
  preferredModeOfDelivery: string;
  isDeleteLoading?: boolean;
  onDeleteDelivery?: (deliveryId: string) => void;
};

export default function SentDeliveryCard({
  imageUrl,
  itemName,
  deliveryLocation,
  receiverImageUrl,
  receiverUsername,
  deliveryStatus,
  price,
  deliveryId,
  preferredModeOfDelivery,
  isDeleteLoading,
  onDeleteDelivery,
}: SentDeliveryCardProps) {
  const isPendingDelivery = deliveryStatus === DeliveryStatus.CREATED && onDeleteDelivery;

  const isAssigned =
    (deliveryStatus === DeliveryStatus.CREATED && !onDeleteDelivery) ||
    deliveryStatus === DeliveryStatus.ACCEPTED ||
    deliveryStatus === DeliveryStatus.ASSIGNED;

  const activedelivery =
    deliveryStatus === DeliveryStatus.PICKED_UP ||
    deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER ||
    deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER;

  return (
    <Card className="w-full max-w-md rounded-xl border bg-base-white shadow-lg overflow-hidden p-4 flex flex-col gap-3">
      {/* Header Section */}
      <div className="flex items-center gap-3">
        {/* Image */}
        <img src={imageUrl} alt={itemName} className="w-16 h-16 rounded-lg object-cover" />
        {/* Title and Location */}
        <div className="flex-1 min-w-0">
          <h3 className="text-lg font-semibold text-gray-900 truncate">{itemName}</h3>
          <p className="flex items-center text-gray-500">
            <MapPin className="mr-1" size={16} />
            <span className="truncate text-sm">{deliveryLocation}</span>
          </p>
        </div>

        {/* Price */}
        <div className="flex items-center text-lg font-bold text-pi-primary-default">
          <span>{price}</span>
          <Pi className="ml-1" size={18} />
        </div>
      </div>

      {/* Middle Section */}
      <div className="flex justify-between items-center">
        {/* User Info */}
        <div className="flex items-center gap-2">
          <ModesOfDeliveryIcon
            modesOfDelivery={[...preferredModeOfDelivery.split(',')]}
            iconColor="stroke-slate-100"
            iconSize={16}
          />
          <div className="flex items-center gap-1.5">
            <Avatar className="w-8 h-8">
              <AvatarImage src={receiverImageUrl} />
            </Avatar>
            <span className="text-sm text-gray-700 truncate">@{receiverUsername}</span>
          </div>
        </div>
        {/* Delivery Status */}
        <div className="flex items-center gap-1">
          {(activedelivery || isPendingDelivery || isAssigned) && (
            <Dot className={`h-3 w-3 ${isPendingDelivery ? 'bg-orange-500' : 'bg-green-500'}`} />
          )}
          <span
            className={cn('text-sm font-medium text-gray-600', {
              'text-pi-primary-default': !isPendingDelivery && !activedelivery && !isAssigned,
            })}
          >
            {isPendingDelivery
              ? 'Pending'
              : isAssigned
              ? 'Assigned'
              : activedelivery
              ? 'In Transit'
              : 'Delivered 🎉'}
          </span>
        </div>
      </div>

      {/* Footer Section */}
      <div className="flex justify-between items-center">
        {/* Track Button */}
        <Button asChild fill="ghost" className="text-pi-primary-default p-0">
          <Link to="/receiver/timeline" state={{ deliveryId }} className="flex items-center">
            <span>{isPendingDelivery ? 'View' : 'Track'}</span>
            <ChevronRight className="ml-1" size={20} />
          </Link>
        </Button>

        {/* Delete Button */}

        <Button
          fill="ghost"
          className="flex items-center text-red-600 hover:bg-red-100 rounded-full p-2 disabled:bg-transparent disabled:text-gray-400"
          disabled={!onDeleteDelivery || isDeleteLoading}
          loading={isDeleteLoading}
          onClick={() => onDeleteDelivery && onDeleteDelivery(deliveryId)}
        >
          <Trash2 size={20} />
        </Button>
      </div>
    </Card>
  );
}
