import Tag from '@/components/custom/tag';
import { Card } from '@/ui/card';
import { Avatar, AvatarImage } from '@/ui/avatar';
import { Button } from '@/ui/button';
import { ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Link } from 'react-router-dom';

type ReceivedDeliveryCardProps = {
  imageUrl: string;
  itemName: string;
  deliveryStatus: string;
  deliveryTime: string;
  deliveryId: string;
  trackingNumber: string | null;
};

const getTextBasedOnStatus = (status: string) => {
  switch (status) {
    case DeliveryStatus.CREATED:
    case DeliveryStatus.ASSIGNED:
    case DeliveryStatus.ACCEPTED:
      return {
        color: '#FF9927',
        text: 'Pending',
        bg: '#FCEDFC',
      };
    case DeliveryStatus.CANCELLED:
      return {
        color: '#FF0000',
        text: 'Cancelled',
        bg: '#f7dada',
      };
    case DeliveryStatus.REJECTED:
      return {
        color: '#FF0000',
        text: 'Rejected',
        bg: '#f7dada',
      };
    case DeliveryStatus.PICKED_UP:
    case DeliveryStatus.IN_TRANSIT_TO_RECEIVER:
      return {
        color: '#D919D9',
        text: 'Picked up and in transit',
        bg: '#FCEDFC',
      };
    case DeliveryStatus.IN_TRANSIT_TO_SENDER:
      return {
        color: '#802280',
        text: 'In transit to sender',
        bg: '#FCEDFC',
      };
    default:
      return {
        color: '#228056',
        text: 'Delivered',
        bg: '#EDFCF6',
      };
  }
};

export default function ReceivedDeliveryCard({
  imageUrl,
  itemName,
  deliveryStatus,
  deliveryTime,
  trackingNumber,
  deliveryId,
}: ReceivedDeliveryCardProps) {
  const tag = getTextBasedOnStatus(deliveryStatus);
  const { color: tagtextColor, text: tagtext, bg: tagBg } = tag;

  return (
    <Card
      className={cn(
        'w-full flex-row  justify-between gap-0 rounded-lg bg-base-white px-2 py-3 shadow'
      )}
    >
      <div className="flex items-center gap-2">
        <Avatar className="h-full w-[25%] rounded-lg">
          <AvatarImage alt="item" src={imageUrl} />
        </Avatar>
        <div className="flex flex-col items-start">
          <h5>{itemName}</h5>
          {trackingNumber && <span className="mb-1 text-sm">#{trackingNumber}</span>}
          <Tag className={`bg-[${tagBg}] text-sm  text-[${tagtextColor}] font-medium`}>
            {tagtext}
          </Tag>
        </div>
      </div>
      <div>
        <Button
          asChild
          className="flex h-2 items-center gap-0 px-0 py-2 text-pi-primary-dark"
          fill="ghost"
        >
          <Link state={{ deliveryId }} to="/receiver/timeline">
            <div className=" p-0 font-semibold text-pi-primary-default">Track</div>
            <ChevronRight />
          </Link>
        </Button>
        <span className="text-xs">{deliveryTime}</span>
      </div>
    </Card>
  );
}
