import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CourierProfile } from "./authSlice";

const initialState: CourierProfile = {
  id: "",
  user: {
    userUid: "",
    username: "",
    walletAddress: "",
    profileImgUrl: "",
    accessToken: "",
    activeProfile: {
      name: "courier",
    },
    createdAt: "",
  },
  isActive: false,
  modesOfDelivery: "",
  preferredModeOfDelivery: "",
  country: "",
  state: "",
  city: "",
  preferredDeliveryAmount: 0,
  earnings: 0,
  stakeAmount: 0,
  deliveries: [],
  reviews: [],
};

const courierSlice = createSlice({
  name: "courier",
  initialState,
  reducers: {
    setCourierProfile(_state, action: PayloadAction<CourierProfile>) {
      return action.payload;
    },
    updateCourierActiveStatus(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
});

export const { setCourierProfile, updateCourierActiveStatus } =
  courierSlice.actions;
export default courierSlice.reducer;
