import React from 'react';
import { Bike, Car, Footprints, Truck } from 'lucide-react';
import { cn } from '@/lib/utils';

// const modesOfDelivery = ['motorbike', 'walk', 'car', 'truck', ]
type Props = {
  iconColor?: string;
  modesOfDelivery: string[];
  className?: string;
  iconSize?: number;
};

const ModesOfDeliveryIcon: React.FC<Props> = ({
  iconColor,
  modesOfDelivery,
  className = 'bg-pi-primary-default',
  iconSize,
}) => {
  return (
    <div className={`relative isolate mr-[${(modesOfDelivery.length - 1) * 3}px] `}>
      {modesOfDelivery.map((mode: string, index: number) => {
        const iconHandler = (MOD: string) => {
          if (MOD.toLowerCase() === 'motorbike') {
            return (
              <Bike
                size={iconSize ? iconSize : 10}
                className={iconColor ? iconColor : 'stroke-slate-100'}
              />
            );
          }
          if (MOD.toLowerCase() === 'car') {
            return (
              <Car
                size={iconSize ? iconSize : 10}
                className={iconColor ? iconColor : 'stroke-slate-100'}
              />
            );
          }
          if (MOD.toLowerCase() === 'truck') {
            return (
              <Truck
                size={iconSize ? iconSize : 10}
                className={iconColor ? iconColor : 'stroke-slate-100'}
              />
            );
          }
          if (MOD.toLowerCase() === 'walk') {
            return (
              <Footprints
                size={iconSize ? iconSize : 10}
                className={iconColor ? iconColor : 'stroke-slate-100'}
              />
            );
          }
        };
        if (index > 0) {
          return (
            <div
              key={index}
              className={cn(
                `h-6 w-6 absolute -right-[${index * 3}px] top-0 opacity-${
                  100 - index * 25
                } rounded-full flex items-center justify-center -z-${
                  index * 10
                } border border-base-white`,
                className
              )}
            >
              {iconHandler(mode)}
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className={cn(
                'h-6 w-6 rounded-full flex items-center justify-center opacity-100 border border-base-white',
                className
              )}
            >
              {iconHandler(mode)}
            </div>
          );
        }
      })}
    </div>
  );
};

export default ModesOfDeliveryIcon;
