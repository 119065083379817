// import { RootState } from "@/Store";
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  OnBoarding,
  WelcomePage,
  SederPage,
  SenderHomepage,
  ReceiverPage,
  ProfilePage,
  ReceiverTimeline,
  ReceiverDelivery,
  ReceiverPackage,
  CourierHomepage,
  CourierPage,
  CourierDashboard,
  WalletSettings,
  CourierInfo,
  AllChat,
  ChatScreen,
  MyRequestDetails,
} from '@/pages';
import { Loader } from '@/components';
import { useDispatch } from 'react-redux';
// import { setInitialMessage } from "@/Store/Features/exampleSlice"; //InitialMessage Reducers
// import { useTranslation } from "react-i18next";
// import { changeLanguage } from "i18next";
// import { Button } from "@/ui/button";
import DeliveryForm from './components/delivery-form/delivery-form';
import PrivacyPolicy from './pages/profile-page/privacy-policy';
import NotificationsPage from './pages/profile-page/notifications-page';
import TermAndCondPage from './pages/profile-page/terms-and-conditions';
import { Toaster } from 'react-hot-toast';
import SenderDeliveries from './pages/sender-page/sender-deliveries';

import CourierSetup from './pages/courier-pages/courier-setup';
import VerificationCode from './pages/courier-pages/v-code';
import DeliveryAdvertDetails from './pages/courier-pages/delivery-advert-details';
import VerificationDeliveryDetails from './pages/courier-pages/verification-delivery-details';
import SuccessPage from './components/success-page/success-page';
import CourierBookings from './pages/courier-pages/courier-bookings';
import { useUpdateUserLocationMutation } from './services/user.services';
import { updateUserAddress } from './Store/Features/authSlice';

function App() {
  const [updateUserLocation] = useUpdateUserLocationMutation();

  const dispatch = useDispatch();
  // const initialMessage = useSelector(
  //   (state: RootState) => state.example.initialMessage,
  // );

  const apiKey = import.meta.env.VITE_PUBLIC_GEOAPIFY_API_KEY;

  // const { t } = useTranslation();

  // const changeInitialMessage = () => {
  //   dispatch(setInitialMessage("Initial Message Changed!!"));
  // };

  // useEffect(() => {
  //   const lang = localStorage.getItem("i18nextLng");
  //   if (lang && lang.length > 2) {
  //     changeLanguage("en");
  //   }
  // }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { longitude, latitude } = position.coords;

        setInterval(() => {
          updateUserLocation({ lng: longitude, lat: latitude });
        }, 5000);

        const getUserAddress = async () => {
          try {
            const response = await fetch(
              `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${apiKey}`
            );

            if (response.ok === false) {
              throw new Error('Could not fetch user address');
            }

            const data = await response.json();

            const city = data.features[0].properties.city;
            const country = data.features[0].properties.country;

            dispatch(updateUserAddress({ city, country }));
          } catch (error: any) {
            console.error(error.message);
          }
        };

        getUserAddress();
        setInterval(() => {
          getUserAddress();
        }, 300000);
      });
    }
  }, [apiKey, dispatch, updateUserLocation]);

  // const languageHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   if (e.target.value !== "0") {
  //     changeLanguage(e.target?.value);
  //   }
  // };

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* <Route
              element={
                <div className="flex h-screen flex-col items-center justify-center">
                  <h1 className="text-3xl font-bold underline">
                    {t(`${initialMessage}`)}
                  </h1>
                  <Button
                    className="text-white mt-5  inline-block h-16 rounded-md bg-purple-700 px-10  py-2 uppercase"
                    onClick={changeInitialMessage}
                    type="button"
                  >
                    {t("Change Initial Message")}
                  </Button>
                  <select
                    className="mt-4"
                    name="Language Selector"
                    onChange={languageHandler}
                    title="Language Selector"
                    value={localStorage.getItem("i18nextLng") || "en"}
                  >
                    <option value={0}>Select a Language</option>
                    <option value="en">English</option>
                    <option value="ar">Japanese</option>
                  </select>
                </div>
              }
              path="/translation"
            /> */}

            <Route element={<OnBoarding />} index />
            <Route element={<WelcomePage />} path="welcome" />
            <Route element={<ProfilePage />} path="profile" />
            <Route element={<NotificationsPage />} path="profile/settings/notifications" />

            <Route element={<TermAndCondPage />} path="profile/settings/terms-and-conditions" />
            <Route element={<PrivacyPolicy />} path="profile/settings/privacy-policy" />

            <Route element={<WelcomePage />} path="welcome" />
            <Route element={<DeliveryForm />} path="new-delivery" />

            <Route element={<AllChat />} path="chat" />
            <Route element={<ChatScreen />} path="chat/:chat-id" />

            <Route element={<SuccessPage />} path="/success" />

            <Route element={<CourierPage />} path="courier">
              <Route element={<CourierHomepage />} path="" />
              <Route element={<DeliveryAdvertDetails />} path="advert/:deliveryId" />

              <Route element={<CourierSetup />} path="courier-setup" />

              <Route
                element={<VerificationDeliveryDetails />}
                path="verification-code/:deliveryId"
              />
              <Route element={<VerificationCode />} path="verification-code" />

              <Route element={<CourierDashboard />} path="dashboard" />
              <Route element={<CourierBookings />} path="bookings" />
              <Route element={<WalletSettings />} path="wallet-settings" />
              <Route element={<MyRequestDetails />} path=":deliveryId" />
            </Route>

            <Route element={<SederPage />} path="sender">
              <Route element={<SenderHomepage />} path="" />
              <Route element={<SenderDeliveries />} path="delivery" />
            </Route>
            <Route element={<CourierInfo />} path="sender/courier-info" />
            <Route element={<DeliveryForm />} path="sender/new-delivery" />

            <Route element={<ReceiverPage />} path="receiver">
              <Route element={<ReceiverTimeline />} path="timeline" />
              <Route element={<ReceiverDelivery />} path="delivery" />
              <Route element={<ReceiverPackage />} path="package" />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Toaster
        containerStyle={{ margin: '8px' }}
        gutter={12}
        position="top-center"
        toastOptions={{
          success: {
            duration: 4000,
            iconTheme: {
              primary: 'var(--success-60)',
              secondary: 'var(--primary-light)',
            },
            style: {
              borderLeft: '7px var(--success-60) solid',
            },
          },
          error: {
            duration: 5000,

            style: {
              borderLeft: '7px var(--critical-60) solid',
            },
          },
          icon: '',
          style: {
            fontSize: '16px',
            zIndex: '999',
            minWidth: '100%',
            justifyContent: 'flex-start',

            paddingLeft: '0px',
            paddingRight: '0px',
            marginRight: '0px',
            marginLeft: '0px',
            backgroundColor: 'white',
            color: 'var(--color-grey-700)',
          },
        }}
      />
    </>
  );
}

export default App;
