import React from "react";
import { Circle, CircleDot } from "lucide-react";

interface Props {
  item: string;
  selectedItem: string;
  // eslint-disable-next-line no-unused-vars
  onUpdateSelectedItem: (item: string) => void;
}

const LocationSelectRadio: React.FC<Props> = ({
  item,
  selectedItem,
  onUpdateSelectedItem,
}) => {
  return (
    <button
      className={`flex
        w-full
        items-center
        justify-between
        border
        border-l-0
        border-r-0
        border-t-0
        border-b-gray-300
        px-2
        py-4
        ${selectedItem !== item ? "text-gray-400" : "text-pi-primary-default"}
        `}
      onClick={() => onUpdateSelectedItem(item)}
      title="radioToggler"
      type="button"
    >
      <span className="text-xl">{item}</span>

      {selectedItem === item ? <CircleDot /> : <Circle />}
    </button>
  );
};

export default LocationSelectRadio;
