import { Button } from '@/ui/button';
import { FaCircleCheck } from '@/assets/icons';
import React from 'react';
import { MessageSquare } from 'lucide-react';
import { Delivery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Link } from 'react-router-dom';

interface Props {
  delivery: Delivery;
  onAcceptDelivery: () => void;
  onStartDelivery: () => void;
  isLoading: boolean;
}

const AdvertSection: React.FC<Props> = ({
  delivery,
  onAcceptDelivery,
  onStartDelivery,
  isLoading,
}) => {
  return (
    <div className="sticky bottom-0 px-5 py-5">
      {delivery.deliveryStatus === DeliveryStatus.CREATED && (
        <Button
          className="flex w-full items-center justify-center gap-2"
          disabled={isLoading}
          icon={<FaCircleCheck />}
          onClick={onAcceptDelivery}
        >
          {' '}
          Accept for {delivery.transactionAmount}π
        </Button>
      )}

      {/* {delivery.deliveryStatus !== null && ( */}
      <div className="flex items-center justify-between space-x-2">
        {delivery.deliveryStatus === DeliveryStatus.ASSIGNED && (
          <>
            <Button className="flex w-full items-center justify-center bg-pi-secondary-default text-pi-secondary-light ">
              Pending Payment
            </Button>
            <Button
              className=" flex w-full whitespace-nowrap border-pi-primary-default font-bold text-pi-primary-default"
              fill="outline"
            >
              <Link
                className="flex items-center justify-center gap-2"
                state={{ chatId: delivery.chatId }}
                to={`/chat/${delivery.chatId}`}
              >
                <MessageSquare />
                Chat @{delivery.senderProfile.user.username}
              </Link>
            </Button>
          </>
        )}
        {delivery.deliveryStatus === DeliveryStatus.ACCEPTED && (
          <>
            <Button
              className="flex w-full items-center justify-center bg-pi-primary-default text-pi-secondary-light "
              disabled={isLoading}
              onClick={onStartDelivery}
            >
              Start Delivery
            </Button>
            <Button className="flex w-full items-center justify-center gap-2 bg-green-100 text-green-500 ">
              <FaCircleCheck /> Accepted
            </Button>
          </>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default AdvertSection;
