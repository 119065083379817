import React from 'react';
import { ChevronLeft, ToggleLeft } from 'lucide-react';
import { blueLogo, userIcon } from '@/assets/images';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarImage } from '@/ui/avatar';
import { IoAlertCircle, MdOutlineLocationOn } from '@/assets/icons';

import { useDispatch, useSelector } from 'react-redux';
import { Delivery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { useUpdateCourierAvailabilityMutation } from '@/services/courier.services';
import { updateCourierActiveStatus } from '@/Store/Features/courierSlice';
import { RootState } from '@/Store';
import { Loader } from '@/components';

const RequestItem = ({
  delivery,
  navigationText,
  statusInfo,
  children,
}: {
  delivery: Delivery;
  navigationText: string;
  statusInfo: string;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCourierActive = useSelector((state: RootState) => state.courier.isActive);

  const [updateCourierAvailabilty, { isLoading: isUpdatingAvailability }] =
    useUpdateCourierAvailabilityMutation();

  const handleUpdateCourierAvailability = async (status: boolean) => {
    try {
      await updateCourierAvailabilty({ isActive: status });
      dispatch(updateCourierActiveStatus(status));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-100">
      {isCourierActive === false && (
        <div className="fixed z-10 flex h-full w-full items-center justify-center bg-black/50">
          {isUpdatingAvailability === false ? (
            <div className="flex w-[80%] flex-col items-center justify-between gap-4 rounded-lg bg-base-white p-4 py-9">
              <h4 className="text-2xl">You&apos;re Offline</h4>
              <p className="text-center font-semibold opacity-50 ">
                To be able to accept a delivery, you need to be active
              </p>

              <button
                className=" scale-70 mt-4 flex items-center gap-1 rounded-xl bg-red-300  px-2 py-0.5 text-xs
              "
                onClick={() => {
                  handleUpdateCourierAvailability(true);
                }}
                type="button"
              >
                <span className="font-xs font-bold text-red-500">offline</span>{' '}
                <ToggleLeft size={14} />
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
      <div className="fixed z-10 flex w-full items-center justify-between bg-pi-primary-default px-5 py-3 text-base-white">
        <button
          className="flex items-center space-x-2"
          onClick={() => {
            navigate(-1);
          }}
          type="button"
        >
          <ChevronLeft /> <span>{navigationText}</span>
        </button>
        <div className="rounded-full bg-base-white p-2">
          <img alt="logo" className="h-5 w-5" src={blueLogo} />
        </div>
      </div>
      <div className="h-56">
        {delivery.deliveryStatus === DeliveryStatus.ASSIGNED && (
          <div className="fixed flex w-full items-center justify-center bg-pi-secondary-default bg-opacity-50 px-5 py-3 pt-16 text-sm text-base-white shadow ">
            <IoAlertCircle className="h-8 w-8" />
            <span className="text-center text-sm">
              You will be notified as soon as the sender grants your request
            </span>
          </div>
        )}
        <img alt="logo" className="h-full w-full object-cover" src={delivery.itemImage} />
      </div>
      <div className="relative mb-5 bg-stone-50 px-5 pb-5 shadow-sm">
        <Avatar className="absolute -top-5 h-10 w-10 ">
          <AvatarImage src={userIcon} />
        </Avatar>
        <div className=" pt-5 text-gray-500">@{delivery.senderProfile.user.username}</div>
        <div className="mt-5">
          <div className="text-sm text-gray-500">Overview</div>
          <div className="flex justify-between">
            <div>
              <div>{delivery.itemName}</div>
              <div className="text-sm">
                <span className="font-semibold">ID: </span>
                <span className="text-gray-500">{delivery.trackingNumber}</span>
              </div>
            </div>
            <div className=" flex flex-col items-end">
              <div className="font-semibold text-pi-primary-default">
                {delivery.transactionAmount}π
              </div>
              <div className="text-sm text-gray-500">{statusInfo}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 flex-1 space-y-4 bg-stone-50 p-5 shadow-sm">
        <div className="text-sm text-gray-500">Location</div>
        <div className="flex items-center justify-between space-x-4">
          <div className="inline-flex w-20 flex-col items-center justify-center bg-gray-100 px-5  py-2 text-gray-500">
            <MdOutlineLocationOn className="  text-2xl   " />
            <div className="text-center text-sm">Pickup Location</div>
          </div>
          <div>{delivery.fromAddress}</div>
        </div>
        <div className="flex items-center justify-between space-x-4">
          <div className="inline-flex w-20 flex-col items-center justify-center bg-gray-100 px-5  py-2 text-gray-500">
            <MdOutlineLocationOn className="  text-2xl   " />
            <div className="text-center text-sm">Dropoff Location</div>
          </div>
          <div>{delivery.toAddress} </div>
        </div>
      </div>

      <div className="space-y-1 bg-stone-50 py-5 shadow-sm">
        <div className="px-5 text-gray-500">{delivery.itemDescription}</div>
        <hr />
      </div>
      {children}
    </div>
  );
};

export default RequestItem;
