import Paragraph from "@/components/custom/paragraph";

export default function TermAndCondPage() {
  return (
    <div>
      <header className=" mb-4  px-5 py-4 shadow">
        <h3 className="is-h4 font-medium ">Terms and Conditions</h3>
      </header>
      <section>
        <Paragraph title="Terms and Conditions of Service for Pailot">
          Welcome to Pailot! This is a decentralized delivery service that
          enables global logistics to be decentralized, fueled by Pi
          cryptocurrency. These terms and conditions (“Terms”) govern your use
          of the Pailot platform, including the Pailot website, mobile
          applications, and other related services (collectively, the
          “Service”). By using Pailot, you agree to abide by these terms of
          service. Please read these Terms carefully before using the Service.{" "}
        </Paragraph>
        <Paragraph title="Definitions">
          In this agreement, "we", "us", and "our" refer to Pailot. "You" refers
          to the user of our decentralized delivery services. "Service" refers
          to the Pailot platform, including our mobile application, website, and
          any other related services.
        </Paragraph>
        <Paragraph title="Acceptance of Terms ">
          By clicking/checking the “I AGREE TO ALLOW PAILOT CONNECT TO MY PI
          ACCOUNT” button/box, accessing the Pailot website or by utilizing the
          Pailot services you agree to be bound by these terms of service and
          all exhibits, order forms, and incorporated policies (the “agreement”
          or “terms of service”) or using the Service, you agree to be bound by
          these Terms. If you do not agree to these Terms, you should not access
          or use the Service. Pailot reserves the right to modify these Terms at
          any time without notice. Your continued use of the Service following
          any such modification constitutes your agreement to be bound by the
          modified Terms.
        </Paragraph>
        <Paragraph title="Eligibility ">
          You must be at least 18 years old to use the Service. By accessing or
          using the Service, you represent and warrant that you are at least 18
          years old.{" "}
        </Paragraph>
        <Paragraph title="User Account ">
          To use certain features of the Service, you must create a user
          account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete. You are solely
          responsible for maintaining the confidentiality of your user account
          and password, and for any activity that occurs under your account.
        </Paragraph>
        <Paragraph title="Use of the Service ">
          You may only use the Service for lawful purposes and in accordance
          with these Terms. You agree not to use the Service:
          <ol className="list-[upper-roman]">
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation
            </li>
            <li>
              To engage in any conduct that restricts or inhibits anyone's use
              or enjoyment of the Service, or which, as determined by Pailot,
              may harm Pailot or users of the Service, or expose them to
              liability
            </li>
            <li>
              To impersonate or attempt to impersonate Pailot, a Pailot
              employee, another user, or any other person or entity
            </li>
            <li>
              To engage in any activity that disrupts or interferes with the
              Service (or the servers and networks which are connected to the
              Service)
            </li>
            <li>
              To use any robot, spider, or other automatic device, process, or
              means to access the Service for any purpose, including monitoring
              or copying any of the material on the Service
            </li>
          </ol>
        </Paragraph>
        <Paragraph title="User Conduct ">
          You agree to use the Service in compliance with all applicable laws
          and regulations, and in a manner that does not infringe on the rights
          of third parties or interfere with their use of the Service. In
          particular, you agree not to:
          <ol className="list-[upper-roman]">
            <li>
              Upload or post any material that is defamatory, obscene,
              pornographic, indecent, abusive, or otherwise objectionable
            </li>
            <li>
              Use the Service to engage in any fraudulent or illegal activity
            </li>
            <li>
              Upload or post any material that infringes on any patent,
              trademark, trade secret, copyright, or other proprietary rights of
              any party
            </li>
            <li>
              Upload or post any material that contains viruses, Trojan horses,
              worms, time-bombs, keystroke loggers, spyware, adware, or any
              other harmful programs or similar computer code designed to
              adversely affect the operation of any computer software or
              hardware.
            </li>
          </ol>
        </Paragraph>
        <Paragraph title="Intellectual Property ">
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by Pailot or its licensors and are
          protected by and construed in accordance with the laws of the
          Jurisdiction in which Pailot operates. You agree not to reproduce,
          distribute, modify, alter, create nor sell derivative works of our
          services or any content or materials contained in our services without
          our prior written permission.
        </Paragraph>
        <Paragraph title="Payment ">
          All payments on Pailot will be made using Pi (the cryptocurrency). You
          are responsible for ensuring that you have sufficient Pi to pay for
          the services you require; all payments are non-refundable. By using
          our services, you agree to pay all fees and charges associated with
          your use of our services. We reserve the right to change our fees and
          charges at any time. Delivery Services Pailot is not liable for any
          damages or losses that may occur during the delivery process. We will
          use reasonable efforts to ensure that your package is delivered on
          time and in good condition. However, we do not guarantee delivery
          times or the condition of the package upon delivery.
        </Paragraph>
        <Paragraph title="Liability ">
          Pailot is not responsible for any damages or losses that may occur as
          a result of your use of Pailot. We will not be liable for any direct,
          indirect, incidental, special, or consequential damages arising out of
          or in connection with the use or inability to use Pailot Services.{" "}
        </Paragraph>
        <Paragraph title="Indemnification ">
          You agree to indemnify and hold harmless Pailot and its directors,
          officers, employees, and agents from and against any and all claims,
          damages, liabilities, costs, and expenses (including reasonable
          attorney's fees) arising out of or in connection with your use of
          Pailot.{" "}
        </Paragraph>
        <Paragraph title="Termination">
          Pailot reserves the right to terminate your account at any time
          without notice or liability. You may also terminate your account at
          any time by contacting us.{" "}
        </Paragraph>
        <Paragraph title="Modification of Terms ">
          Pailot reserves the right to modify these terms and conditions of
          service policy at any time without prior notice. Your continued use of
          Pailot after any modifications to the terms and conditions of service
          policy constitutes your acceptance of the modified terms.{" "}
        </Paragraph>
        <Paragraph title="Governing Law ">
          These terms and conditions of service policy shall be governed by and
          construed in accordance with the laws of the jurisdiction in which
          Pailot operates.{" "}
        </Paragraph>
        <Paragraph title="Entire Agreement ">
          These terms and conditions of service policy constitute the entire
          agreement between you and Pailot with respect to the use of Pailot.{" "}
        </Paragraph>
        <Paragraph title="Contact Us ">
          If you have any questions or concerns about these terms and
          conditions, contact us via our email address: pailot.pr@gmail.com
        </Paragraph>
      </section>
    </div>
  );
}
