import React from 'react';
import {  userIcon } from '@/assets/images';
import { Button } from '@/ui/button';
import { Ellipsis } from '@/assets/icons';
import { Delivery, useUpdateDeliveryStatusMutation } from '@/services/delivery.services';
import { useNavigate } from 'react-router-dom';
import { DeliveryStatus } from '@/types/pailot-interfaces';

interface Props {
  onTransit: boolean;
  delivery: Delivery
  blur?: boolean
}

const DeliveryItem: React.FC<Props> = ({ onTransit, delivery, blur }) => {
  const navigate = useNavigate();
  const [updateDeliveryStatus, { isLoading: isUpdatingStatus }] = useUpdateDeliveryStatusMutation();

  const handleStartDelivery = async () => {
    try {
      const updatedDelivery = await updateDeliveryStatus({
        deliveryId: delivery.id,
        status: DeliveryStatus.IN_TRANSIT_TO_SENDER,
      }).unwrap();
      // setDelivery((prev) => ({ ...prev, ...updatedDeliery }));
      console.log(updatedDelivery)
    } catch (error) {
      console.log(error);
      throw new Error('Failed to start delivery');
    }
  };
  return (
    <div className={`flex flex-col gap-2 rounded-lg bg-base-white p-4 ${blur && "opacity-30"}`}>
      <div className="flex items-center justify-between gap-4">
        <img
          alt="Item"
          className="h-[30px] w-[30px] rounded-lg border border-gray-300 object-cover"
          src={delivery.itemImage}
        />
        <p className=" flex-1 text-sm">{delivery.itemName}</p>

        <span className="text-sm font-semibold text-pi-primary-default">{delivery.transactionAmount}π</span>
      </div>
      <div className="flex items-center justify-between gap-2">
        <img alt="sender icon" className="h-[20px] w-[20px] rounded-full" src={userIcon} />
        <p className="flex-1 text-xs">@{delivery.senderProfile.user.username}</p>
        <span className={`text-xs ${onTransit ? 'text-green-600' : 'text-amber-900'}`}>
          {onTransit ? 'In transit' : 'Awaiting'}
        </span>
      </div>
      <div
        className="
          flex gap-4"
      >
        <div className="flex flex-col">
          <span className="text-xs">Sender Location</span>
          <p className="text-sm font-semibold">{delivery.fromAddress}</p>
        </div>{' '}
        <div className="flex flex-col">
          <span className="text-xs">Receiver Location</span>
          <p className="text-sm font-semibold">{delivery.toAddress}</p>
        </div>
      </div>
      <div
        className="
          flex gap-4"
      >
        {onTransit ? (
          <Button className="flex-1 bg-green-600 hover:bg-green-600">Delivery in progress</Button>
        ) : (
          <Button disabled={blur === true || isUpdatingStatus} className="flex-1"
          onClick={handleStartDelivery}
          >Start this Delivery</Button>
        )}

        <Button
          className="flex w-[35%] items-center justify-center gap-2 bg-pi-secondary-default hover:bg-pi-secondary-default"
          disabled={blur === true || isUpdatingStatus}
          onClick={() => {
            navigate(`/courier/advert/${delivery.id}`);
          }}
        >
          More
          <img alt="icon" className="h-[18px]" src={Ellipsis} />
        </Button>
      </div>
    </div>
  );
};

export default DeliveryItem;
