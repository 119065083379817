import { Switch } from '@/ui/switch';

export default function NotificationsPage() {
  return (
    <div>
      <h4 className="is-h4 bg-white mb-8 px-5 py-4 font-medium shadow">Notifications</h4>
      <div className="mx-5">
        <div className="mb-8 flex items-center justify-between">
          <div>
            <h5 className="mb-1 text-sm text-stone-600">In-app</h5>
            <p className="text-[11px] font-medium text-stone-500">
              Keep tabs of all alert in the notification page
            </p>
          </div>
          <Switch activeColor="[#039855]" className="bg-gray-300" thumbStyle="bg-pi-primary-default" />
        </div>

        <div className="mb-8 flex items-center justify-between">
          <div>
            <h5 className="mb-1 text-sm text-stone-600">Email</h5>
            <p className="text-[11px] font-medium text-stone-500">
              Receive email notifications when alerts get triggered
            </p>
          </div>
          <Switch activeColor="[#039855]" className="bg-gray-300" thumbStyle="bg-pi-primary-default" /> 
        </div>

        <div className="mb-8 flex items-center justify-between">
          <div>
            <h5 className="mb-1 text-sm text-stone-600">SMS</h5>
            <p className="text-[11px] font-medium text-stone-500">
              Receive sms messages when alerts get triggered
            </p>
          </div>
          <Switch activeColor="[#039855]" className="bg-gray-300" thumbStyle="bg-pi-primary-default" />
        </div>
      </div>
    </div>
  );
}
