import { CourierProfile } from '@/Store/Features/authSlice';
import { Message, Participant } from '@/Store/Features/chatSlice';
import { MessageType, ParticipantType } from '@/services/chat.services';
import { Delivery } from '@/services/delivery.services';
import { Review } from '@/services/review.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { formatDuration, intervalToDuration } from 'date-fns';

export function formatCourierData(courier: CourierProfile) {
  const {
    id,
    city,
    state,
    country,
    modesOfDelivery,
    preferredDeliveryAmount,
    reviews,
    deliveries,
  } = courier;
  const { username } = courier.user;

  const completedDeliveries = deliveries.filter(
    (delivery) => delivery.deliveryStatus === DeliveryStatus.DELIVERED
  );

  return {
    name: username,
    id,
    deliveries: deliveries.length,
    successRate: +deliverySuccessRate(completedDeliveries, deliveries).toFixed(2),
    rating: averageRating(reviews),
    comments: reviews.length,
    deliveryPrice: preferredDeliveryAmount,
    averageDeliveryTime: averageDeliveryTime(completedDeliveries),
    deliveryRegion: {
      country,
      state,
      city,
    },
    modesOfDelivery,
    preferredModeOfDelivery: courier.preferredModeOfDelivery,
  };
}

export function averageDeliveryTime(completedDeliveries: Delivery[]) {
  // Calculate the average delivery time in minutes
  const deliveryAverageEstimatedDeliveryTime =
    completedDeliveries.length > 0
      ? completedDeliveries.reduce((acc, delivery) => acc + delivery.estimatedDeliveryTime, 0) /
        completedDeliveries.length
      : 0;

  // Format the average delivery time
  let formattedAverageEstimatedDeliveryTime = 'N/A';
  if (deliveryAverageEstimatedDeliveryTime > 0) {
    const duration = intervalToDuration({
      start: 0,
      end: deliveryAverageEstimatedDeliveryTime * 60 * 1000, // Convert minutes to milliseconds
    });

    formattedAverageEstimatedDeliveryTime = formatDuration(duration, {
      format: ['hours', 'minutes'], // Only display hours and minutes
    });
  }

  return formattedAverageEstimatedDeliveryTime;
}

export function deliverySuccessRate(completedDeliveries: Delivery[], deliveries: Delivery[]) {
  const deliverySuccessRate =
    completedDeliveries.length > 0 ? (completedDeliveries.length / deliveries.length) * 100 : 0;
  return deliverySuccessRate;
}

export function averageRating(reviews: Review[]) {
  const averageRating =
    reviews.length > 0
      ? reviews.reduce((acc, review) => (acc += review.rating), 0.0) / reviews.length
      : 0.0;
  return averageRating;
}

export function formatMessage(chatId: string, message: MessageType): Message {
  return {
    id: message.id,
    content: message.content,
    chatId,
    mediaUrl: message.mediaUrl,
    mediaType: message.mediaType,
    createdAt: message.createdAt,
    sender: message.senderId
      ? {
          userUid: message.senderId.userUid,
          username: message.senderId.username,
          profileImgUrl: message.senderId.profileImgUrl,
        }
      : null,
    readStatus: message.readStatus
      ? message.readStatus.map((status) => ({
          id: status.id,
          readAt: status.readAt,
          messageId: status.messageId,
          userId: status.userId,
        }))
      : [],
    messageType: message.type,
  };
}

export function formatMessages(messages: MessageType[], chatId: string): Message[] {
  return messages.map((message) => formatMessage(chatId, message));
}

export function formatParticipant(participant: ParticipantType): Participant {
  return {
    id: participant.id,
    user: {
      userUid: participant.userId.userUid,
      username: participant.userId.username,
      profileImgUrl: participant.userId.profileImgUrl,
    },
    role: participant.role,
  };
}

export function formatParticipants(participants: ParticipantType[]): Participant[] {
  return participants.map(formatParticipant);
}

export function getLastReadMessage(messages: Message[], currentUserId: string): Message | null {
  let lastReadMessage: Message | null = null;
  let latestReadAt: Date | null = null;

  messages.forEach((message) => {
    message.readStatus.forEach((status) => {
      if (status.userId === currentUserId) {
        if (!latestReadAt || status.readAt > latestReadAt) {
          latestReadAt = status.readAt;
          lastReadMessage = message;
        }
      }
    });
  });

  return lastReadMessage;
}
