import { CourierProfile } from '@/Store/Features/authSlice';
import baseApi from './base.services';
import {
  APPROVE_STAKE_PAYMENT,
  COMPLETE_STAKE_PAYMENT,
  CANCELLED_STAKE_PAYMENT,
} from '@/constants/config';

export type PaymentDTO = {
  identifier: string;
  user_uid: string;
  amount: number;
  memo: string;
  metadata: object;
  from_address: string;
  to_address: string;
  direction: 'user_to_app' | 'app_to_user';
  created_at: string;
  network: string;
  status: {
    developer_approved: boolean;
    transaction_verified: boolean;
    developer_completed: boolean;
    cancelled: boolean;
    user_cancelled: boolean;
  };
  transaction: null | {
    txid: string;
    verified: boolean;
    _link: string;
  };
};

export const stakeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    approveStakePayment: builder.mutation<
      CourierProfile,
      {
        paymentId: string;
      }
    >({
      query: ({ paymentId }) => ({
        url: APPROVE_STAKE_PAYMENT,
        method: 'POST',
        body: { paymentId },
      }),
    }),
    completeStakePayment: builder.mutation<
      void,
      {
        paymentId: string;
        txid: string;
        courierData: {
          city: string;
          state: string;
          country: string;
          preferredModeOfDelivery: string;
          preferredDeliveryAmount: number;
          stakeAmount: number;
          modesOfDelivery: string;
        };
      }
    >({
      query: (body) => ({
        url: COMPLETE_STAKE_PAYMENT,
        method: 'POST',
        body,
      }),
    }),
    cancelledStakePayment: builder.mutation<void, { paymentId: string }>({
      query: ({ paymentId }) => ({
        url: CANCELLED_STAKE_PAYMENT,
        method: 'POST',
        body: { paymentId },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useApproveStakePaymentMutation,
  useCompleteStakePaymentMutation,
  useCancelledStakePaymentMutation,
} = stakeApi;
