import { CommandEmpty, CommandGroup, CommandItem, CommandList } from './command';
import { Command as CommandPrimitive } from 'cmdk';
import {
  useState,
  useRef,
  useCallback,
  useEffect,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Skeleton } from './skeleton';
import { cn } from '@/lib/utils';
import { Check } from 'lucide-react';

export type Option = Record<'value' | 'label', string> & Record<string, string>;

type AutoCompleteProps = {
  options?: Option[];
  emptyMessage?: string;
  value?: Option | null;
  // This function fetches options based on the user's input
  fetchOptions?: (_input: string) => Promise<Option[]>;
  onValueChange?: (_value: Option) => void;
  onInputValueChange?: (_value: string) => void;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  debounceTimeInMs?: number;
  name?: string;
  inputValue?: string;
  invalid?: boolean;
};

export const AutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(
  (
    {
      options = [],
      placeholder,
      name,
      emptyMessage,
      value,
      onValueChange,
      disabled,
      loading = false,
      debounceTimeInMs = 300,
      fetchOptions,
      inputValue,
      invalid = false,
      onInputValueChange,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    // Combine the refs
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option | null>(value as Option);
    const [inputValueInternal, setInputValueInternal] = useState<string | undefined>(
      value?.label || ''
    );
    const [loadingInternal, setLoadingInternal] = useState<boolean>(false);
    // Initialize with static options or empty if fetching dynamically
    const [displayedOptions, setDisplayedOptions] = useState<Option[]>(options);

    useEffect(() => {
      if (inputValue !== undefined) {
        updateInputValue(inputValue);
      }
    }, [inputValue]);

    const updateInputValue = (val: string) => {
      setInputValueInternal(val);
      onInputValueChange?.(val);
    };

    // Effect to handle fetching and debouncing
    useEffect(() => {
      if (!fetchOptions) {
        return;
      }
      const debounceTimer = setTimeout(() => {
        setLoadingInternal(true);
        fetchOptions(inputValueInternal ?? '')
          .then((fetchedOptions) => {
            setDisplayedOptions(fetchedOptions);
            setLoadingInternal(false);
          })
          .catch(() => setLoadingInternal(false));
      }, debounceTimeInMs);

      return () => clearTimeout(debounceTimer);
    }, [fetchOptions, inputValueInternal, debounceTimeInMs]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        const input = inputRef.current;
        if (!input) return;

        if (!isOpen) setOpen(true);

        if (event.key === 'Enter' && input.value !== '') {
          const optionToSelect = displayedOptions.find((option) => option.label === input.value);
          if (optionToSelect) {
            setSelected(optionToSelect);
            onValueChange?.(optionToSelect);
          }
        }

        if (event.key === 'Escape') input.blur();
      },
      [isOpen, displayedOptions, onValueChange]
    );

    const handleBlur = useCallback(() => {
      setOpen(false);
    }, [selected]);

    const handleSelectOption = useCallback(
      (selectedOption: Option) => {
        updateInputValue(selectedOption.label);

        setSelected(selectedOption);
        onValueChange?.(selectedOption);

        setTimeout(() => inputRef.current?.blur(), 0);
      },
      [onValueChange]
    );

    return (
      <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={!fetchOptions}>
        <div>
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValueInternal}
            onValueChange={(val) => {
              updateInputValue(val);
            }}
            onBlur={handleBlur}
            onFocus={() => setOpen(true)}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            className={cn(
              'text-md ring-offset-background placeholder:text-primary-hint mt-1 flex h-10 w-full rounded-xs border border-input-border p-3 text-input-content file:border-0 file:bg-transparent file:text-sm file:font-medium focus:border-input-border-active focus:bg-input-background-active focus:text-input-content-active focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed',
              invalid && 'border-input-border-error focus:border-input-border-error'
            )}
            aria-invalid={invalid}
          />
        </div>
        <div className="absolute mt-1">
          {isOpen && (
            <div
              className={cn(
                'border-alpha text-popover-foreground relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-xs border bg-primary shadow-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1'
              )}
            >
              <CommandList>
                {loadingInternal || loading ? (
                  <>
                    <Skeleton className="m-2 h-8 w-full max-w-72 p-1" />
                    <Skeleton className="m-2 h-8 w-full max-w-96 p-1" />
                  </>
                ) : (
                  <div>
                    {displayedOptions.length > 0 && (
                      <CommandGroup>
                        {displayedOptions.map((option) => (
                          <CommandItem
                            key={option.value}
                            value={option.label}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onSelect={() => handleSelectOption(option)}
                            className={cn(
                              'relative flex w-full cursor-pointer select-none items-center rounded-xs py-1.5 pl-8 pr-2 text-sm outline-none'
                            )}
                          >
                            {selected?.value === option.value ? (
                              <span className="absolute left-2 flex size-3.5 items-center justify-center">
                                <Check className="size-4" />
                              </span>
                            ) : null}
                            {option.label}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    )}
                    <CommandEmpty
                      className="select-none px-2 py-3 text-center text-sm"
                      forceRender={displayedOptions.length === 0}
                    >
                      {emptyMessage ?? 'No results found'}
                    </CommandEmpty>
                  </div>
                )}
              </CommandList>
            </div>
          )}
        </div>
      </CommandPrimitive>
    );
  }
);

export default AutoComplete;
