import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import FormHeader from './form-header';
import { Props } from '../state-machine/state-machine';
import { Button } from '@/ui/button';
import InputWithPiIcon from '@/components/Input-with-pi-icon';
import { UseFormReturn } from 'react-hook-form';
import { DeliveryFormData } from '../delivery-form';

function DeliveryPriceForm({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<DeliveryFormData, any, DeliveryFormData>;
}) {
  const {
    formState: { isValid },
    register,
  } = form;

  return (
    <div className="flex h-[100dvh] flex-col justify-between px-5 pb-5">
      <div>
        <FormHeader
          description="The delivery fee amount you are willing to pay the Pailot"
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Finally! Pi Payment"
        />
        <FormField
          name="price"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Price for delivery</FormLabel>
              <FormControl>
                <InputWithPiIcon>
                  <Input
                    className="mt-0 rounded-none border-0 border-none bg-transparent focus:border-0 focus:border-none"
                    min={0}
                    step="any"
                    type="number"
                    {...field}
                    {...register('price', {
                      required: 'Price for delivery is required',
                    })}
                    placeholder="e.g 0.0002"
                  />
                </InputWithPiIcon>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <Button type='button' disabled={!isValid} onClick={() => send({ type: 'NEXT' })} size="large">
        Next
      </Button>
    </div>
  );
}

export default DeliveryPriceForm;
