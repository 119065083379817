import { assign, createMachine } from "xstate";

export type Context = {
  step: number;
  formData: {
    receiverName: string;
    itemName: string;
    itemDescription: string;
    itemCategory: string;
    pickupAddress: string;
    dropOffAddress: string;
    modeOfDelivery: string;
    deliveryId: string;
    price: number;
  };
  isCourierHired: boolean;
};

export type Events =
  | { type: "NEXT" }
  | { type: "PREVIOUS" }
  | { type: "UPDATE"; value: Context };

type States =
  | { value: "step1"; context: Context }
  | { value: "step2"; context: Context }
  | { value: "step3"; context: Context }
  | { value: "step4"; context: Context }
  | { value: "step5"; context: Context }
  | { value: "submit"; context: Context }
  | { value: "success"; context: Context };

export type Props = {
  state: { context: Context };
  // eslint-disable-next-line no-unused-vars
  send: (event: Events) => void;
};

const initialContext: Context = {
  step: 1,
  formData: {
    receiverName: "",
    itemName: "",
    itemDescription: "",
    itemCategory: "",
    pickupAddress: "",
    dropOffAddress: "",
    modeOfDelivery: "",
    deliveryId: "",
    price: 0,
  },
  isCourierHired: false,
};

// @ts-expect-error - XState types are not compatible with the machine
export const deliveryFormMachine = createMachine<Context, Events, States>(
  {
    id: "DeliveryForm",
    initial: "step1",
    context: initialContext,
    on: {
      UPDATE: {
        actions: assign(({ context, event }) => ({
          ...context,
          ...event.value,
        })),
      },
    },
    states: {
      step1: {
        on: {
          NEXT: {
            target: "step2",
            actions: assign((state) => ({
              ...state,
              step: state.context.step + 1,
            })),
          },
        },
      },
      step2: {
        on: {
          NEXT: {
            target: "step3",
            actions: assign((state) => ({
              ...state,
              step: state.context.step + 1,
            })),
          },
          PREVIOUS: {
            target: "step1",
            actions: assign((state) => ({
              ...state,
              step: state.context.step - 1,
            })),
          },
        },
      },
      step3: {
        on: {
          NEXT: [
            {
              target: "submit",
              guard: "courierHired",
              actions: assign((state) => ({
                ...state,
                step: state.context.step + 1,
              })),
            },
            {
              target: "step4",
              guard: "courierNotHired",
              actions: assign((state) => ({
                ...state,
                step: state.context.step + 1,
              })),
            },
          ],
          PREVIOUS: {
            target: "step2",
            actions: assign((state) => ({
              ...state,
              step: state.context.step - 1,
            })),
          },
        },
      },
      step4: {
        on: {
          NEXT: {
            target: "submit", 
          },
          PREVIOUS: { 
            target: "step3",
            actions: assign((state) => ({
              ...state,
              step: state.context.step - 1,
            })),
          },
        },
      },
      submit: {
        on: {
          NEXT: {
            target: "success",
          },
          PREVIOUS: [
            {
              target: "step3",
              guard: "courierHired",
              actions: assign((state) => ({
                ...state,
                step: state.context.step - 1,
              })),
            },
            {
              target: "step4",
              guard: "courierNotHired",
              actions: assign((state) => ({
                ...state,
                step: state.context.step - 1,
              })),
            },
          ],
        },
      },
      success: {
        type: "final",
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    guards: {
      courierHired: ({ context }) => {
        return context.isCourierHired;
      },
      courierNotHired: ({ context }) => {
        return !context.isCourierHired;
      },
    },
  },
);
