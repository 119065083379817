import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import { cn } from '@/lib/utils';
import { Label } from '@/ui/label';

interface FormProps<T = any> {
  children: React.ReactNode;
  className?: string;
  form: UseFormReturn<any>;
  action?: (_formData: T) => Promise<void>;
}

const Form: React.FC<FormProps> = ({ children, form, action, className }) => {
  return (
    <FormProvider {...form}>
      <form
        className={cn(className)}
        noValidate
        // @ts-expect-error server actions not recognized for some reason
        action={action}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          // Fixing a weird error where opening a modal with a from from another form, and submitting the form on the modal will also submit the main form
          // See here: https://github.com/orgs/react-hook-form/discussions/3704#discussioncomment-265857
          event.stopPropagation();
          // If server error happens once, we will not be able to re-submit until the error is cleared
          form.clearErrors('server');

          // If there were no client-side validation errors, invoke the server action
          // and set a "server" error on error so this can be displayed to the user
          return form.handleSubmit(
            async () => {
              if (action) {
                try {
                  return await action(form.getValues());
                } catch (error: any) {
                  form.setError('server', {
                    message: error?.message || 'A server error occurred',
                  });
                }
              }
            },
            (errors) => {
              console.error(errors);
            }
          )(event);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn(className)} {...props} />
      </FormItemContext.Provider>
    );
  }
);
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    disabled?: boolean;
    required?: boolean;
  }
>(({ className, ...props }, ref) => {
  const { error, invalid, formItemId } = useFormField();
  const { required, disabled } = props;

  return (
    <Label ref={ref} className={cn(className)} htmlFor={formItemId} {...props} invalid={invalid}>
      <>
        {props.children}
        {required ? (
          <span className={cn(!error && !disabled && 'text-brand')} aria-label="field required">
            *
          </span>
        ) : null}
      </>
    </Label>
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-muted-foreground text-sm', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn('py-1 text-sm font-normal text-gray-500', className)}
      aria-live="polite"
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
