import { PiIcon } from '@/assets/icons';
import { Button } from '@/ui/button';
import { CheckCircle2 } from 'lucide-react';
import React from 'react';

interface Props {
  name: string;
  amount: number;
  features: string[];
  primaryBgColor: string;
  selected: boolean;
  // eslint-disable-next-line no-unused-vars
  // onButtonClick: (stake: string) => void;
}

const Stake: React.FC<Props> = ({ name, amount, features, primaryBgColor, selected }) => {
  return (
    <div
      className={`
        relative
        flex
       w-full
       min-w-40
       flex-col
       overflow-hidden
       rounded-2xl
      ${primaryBgColor}
       p-4
       text-gray-50
       opacity-50
    `}
    >
      <div
        className={`
       absolute
       -right-8
        -top-6
        aspect-square
        w-28
        rounded-full
        bg-gray-50
        opacity-20
        `}
      />
      <div className="z-10 flex items-center justify-between">
        <h4 className={`text-xl ${name === 'vip' ? 'uppercase' : 'capitalize'} text-gray-50`}>
          {name}
        </h4>
        <div className="z-10 flex items-center gap-1">
          <img alt="Pi Icon" src={PiIcon} />
          <span className="text-xl font-medium text-gray-50">{amount}</span>
        </div>
      </div>
      <ul className="z-10 mb-8 mt-1 flex flex-1 grow flex-col gap-2">
        {features.map((feature: string) => (
          <li className="z-10 flex items-center gap-1 text-xs" key={feature}>
            <CheckCircle2 size={25} />
            <p className="w-4/5">{feature}</p>
          </li>
        ))}
      </ul>
      <Button
        className="w-full rounded-l bg-gray-50 font-bold text-pi-primary-default hover:border hover:bg-transparent hover:text-gray-50 disabled:text-inverse"
        disabled={!selected}
        type={selected ? 'submit' : 'button'}
      >
        {selected ? 'Stake and save' : 'Select'}
      </Button>
    </div>
  );
};

export default Stake;
