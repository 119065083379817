/*global google */

import { Loader } from '@googlemaps/js-api-loader';
import { AutoComplete, Option } from '@/ui/autocomplete';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '@/ui/input';
import { useFormContext } from 'react-hook-form';

interface GooglePlacesAutoCompleteProps {
  onAddressSelected: (addressComponents: google.maps.GeocoderAddressComponent[]) => void;
  placeholder?: string;
  value?: string;
  invalid?: boolean;
  name?: string;
  stateUpdate?: (value: boolean) => void;
}

const GooglePlacesAutoComplete = forwardRef<HTMLInputElement, GooglePlacesAutoCompleteProps>(
  (
    {
      onAddressSelected,
      placeholder = 'Search for an address',
      value,
      stateUpdate,
      invalid = false,
      name = 'address',
    },
    ref
  ) => {
    const form = useFormContext();

    const apiKey = import.meta.env.VITE_PUBLIC_GOOGLE_MAPS_API_KEY;

    const [autocompleteService, setAutocompleteService] =
      useState<google.maps.places.AutocompleteService | null>(null);
    const [sessionToken, setSessionToken] =
      useState<google.maps.places.AutocompleteSessionToken | null>(null);
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(
      null
    );

    const placesDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!apiKey) {
        throw new Error('Google Maps API key is required');
      }

      const loader = new Loader({
        apiKey,
        version: 'weekly',
        libraries: ['places'],
      });

      loader
        .importLibrary('places')
        .then(({ AutocompleteService, AutocompleteSessionToken, PlacesService }) => {
          if (!placesDivRef.current) {
            throw new Error('The PlacesService requires a div element to be initialized.');
          }

          setAutocompleteService(new AutocompleteService());
          setSessionToken(new AutocompleteSessionToken());
          setPlacesService(new PlacesService(placesDivRef.current));
        });
    }, [apiKey]);

    const fetchOptions = useCallback(
      (input: string): Promise<Option[]> => {
        return new Promise((resolve) => {
          if (!input || !autocompleteService || !sessionToken) {
            return resolve([]);
          }

          autocompleteService.getPlacePredictions(
            { input, sessionToken },
            (predictions, status) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
                return resolve([]);
              }

              const options: Option[] = predictions.map((prediction) => ({
                label: prediction.description,
                value: prediction.place_id,
              }));

              resolve(options);
            }
          );
        });
      },
      [sessionToken, autocompleteService]
    );

    if (!autocompleteService || !sessionToken) {
      return (
        <>
          <div className="display-none" ref={placesDivRef} />
          <Input aria-invalid={invalid} ref={ref} />
        </>
      );
    }

    return (
      <AutoComplete
        fetchOptions={fetchOptions}
        inputValue={value}
        invalid={invalid}
        name={name}
        onInputValueChange={(v) => {
          form.setValue(name, v);
          if (stateUpdate) {
            if (v.length > 0) {
              stateUpdate(true);
            } else {
              stateUpdate(false);
            }
          }
        }}
        onValueChange={(v) => {
          placesService?.getDetails(
            {
              placeId: v.value,
              sessionToken,
              fields: ['address_components'],
            },
            (place, status) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK || !place) {
                return;
              }
              onAddressSelected(place.address_components ?? []);
            }
          );
        }}
        placeholder={placeholder}
        ref={ref}
      />
    );
  }
);

GooglePlacesAutoComplete.displayName = 'GooglePlacesAutoComplete';

export default GooglePlacesAutoComplete;
