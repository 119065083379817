import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';

const cardVariants = cva('flex flex-col font-sans text-secondary-content', {
  variants: {
    variant: {
      image: 'w-64 gap-5',
      course: 'max-w-[580px] gap-14 p-8 shadow-md',
      info: 'max-w-[460px] gap-5',
      custom: '',
    },
  },
  defaultVariants: {
    variant: 'course',
  },
});

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} className={cn(cardVariants({ variant }), className)} {...props} />
));
Card.displayName = 'Card';

export { Card };
