import { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import { SocketContext } from "./socket-context";

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSocketReady, setIsSocketReady] = useState(false);
  const socketRef = useRef<Socket | null>(null);

  const socketUrl =
    import.meta.env.VITE_REACT_APP_SOCKET_URL || "ws://localhost:3333/chat";

  useEffect(() => {
    if (!socketRef.current) {
      console.log("Initializing new socket connection");
      socketRef.current = io(socketUrl, {
        transports: ["websocket"],
        autoConnect: false,
        query: {
          token: localStorage.getItem("accessToken"),
        },
      });
      socketRef.current.connect();
      socketRef.current.on("connect", () => {
        console.log("Socket connected:", socketRef.current?.id);
        setIsSocketReady(true);
      });
    }
    return () => {
      console.log("Disconnecting socket...");
      socketRef.current?.disconnect();
      socketRef.current?.off("disconnect", () => {
        console.log("Socket disconnected");
        setIsSocketReady(false);
      });
    };
  }, [socketUrl]);

  return (
    <SocketContext.Provider
      value={{ socket: socketRef.current, isReady: isSocketReady }}
    >
      {children}
    </SocketContext.Provider>
  );
};
