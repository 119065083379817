import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStackedLineChart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" viewBox="0 0 20 20" aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="#302F33" d="M1.5 19.115.385 18l7.154-7.154 4 4 7.042-8 1.054 1.034-8.077 9.216-4.02-4.02zm0-6L.385 11.999l7.154-7.153 4 4 7.042-8 1.054 1.034-8.077 9.216-4.02-4.02z" /></svg>;
export default SvgStackedLineChart;