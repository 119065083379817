import { CourierProfile } from "@/Store/Features/authSlice";
import baseApi from "./base.services";
import {
  CREATE_COURIER_PROFILE,
  GET_COURIER_PROFILE,
  GET_COURIER_PROFILE_BY_ID,
  UPDATE_COURIER_EARNINGS,
  UPDATE_COURIER_AVAILABILITY,
  UPDATE_COURIER_DELIVERY_AMOUNT,
  UPDATE_COURIER_DELIVERY_REGION,
  UPDATE_COURIER_MODES_OF_DELIVERY,
} from "@/constants/config";

export const courierApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCourierProfile: builder.mutation<
      CourierProfile,
      {
        city: string;
        state: string;
        country: string;
        preferredModeOfDelivery: string;
        preferredDeliveryAmount: number;
        stakeAmount: number;
        modesOfDelivery: string;
      }
    >({
      query: (body) => ({
        url: CREATE_COURIER_PROFILE,
        method: "POST",
        body,
      }),
    }),
    getCourierProfile: builder.query<CourierProfile, void>({
      query: () => GET_COURIER_PROFILE,
      keepUnusedDataFor: 0,
    }),
    getCourierProfileById: builder.query<CourierProfile, { id: string }>({
      query: (body) => GET_COURIER_PROFILE_BY_ID(body.id),
    }),
    updateCourierEarnings: builder.mutation<
      CourierProfile,
      { earnings: number }
    >({
      query: (body) => ({
        url: UPDATE_COURIER_EARNINGS,
        method: "PUT",
        body,
      }),
    }),
    updateCourierAvailability: builder.mutation<
      CourierProfile,
      { isActive: boolean }
    >({
      query: (body) => ({
        url: UPDATE_COURIER_AVAILABILITY,
        method: "PUT",
        body,
      }),
    }),
    updateCourierDeliveryAmount: builder.mutation<
      CourierProfile,
      { deliveryAmount: number }
    >({
      query: (body) => ({
        url: UPDATE_COURIER_DELIVERY_AMOUNT,
        method: "PUT",
        body,
      }),
    }),
    updateCourierDeliveryRegion: builder.mutation<
      CourierProfile,
      {
        address: string;
        addressObject: {
          city: string;
          state: string;
          country: string;
        };
      }
    >({
      query: (body) => ({
        url: UPDATE_COURIER_DELIVERY_REGION,
        method: "PUT",
        body,
      }),
    }),
    updateCourierModesOfDelivery: builder.mutation<
      CourierProfile,
      { modesOfDelivery: string }
    >({
      query: (body) => ({
        url: UPDATE_COURIER_MODES_OF_DELIVERY,
        method: "PUT",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCourierProfileMutation,
  useGetCourierProfileByIdQuery,
  useGetCourierProfileQuery,
  useUpdateCourierEarningsMutation,
  useUpdateCourierAvailabilityMutation,
  useUpdateCourierDeliveryAmountMutation,
  useUpdateCourierDeliveryRegionMutation,
  useUpdateCourierModesOfDeliveryMutation,
} = courierApi;
