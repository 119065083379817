export enum PaymentStatus {
  CREATED = "created",
  SUBMITTED = "submitted",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

export enum DeliveryStatus {
  CREATED = "Created", // Sender created a delivery and it has not been "accepted" by a courier
  ASSIGNED = "Assigned", // A courier has "accepted" the delivery
  ACCEPTED = "Accepted", // A sender has made payment for the delivery. It is the sender's way of accepting that the courier and the delivery should take place
  REJECTED = "Rejected", // when the delivery is rejected
  CANCELLED = "Cancelled",
  RETURNED = "Returned", //
  IN_TRANSIT_TO_SENDER = "In Transit to Sender", // Set when user clicks on start delivery
  PICKED_UP = "Picked up", //
  IN_TRANSIT_TO_RECEIVER = "In Transit to Receiver", // Set after 15secs of entering a successful pickup code (the endpoint resolved successfully)
  DELIVERED = "Delivered",
}

export enum ItemCategory {
  FOOD_DELIVERY = "Food delivery",
  ELECTRONICS = "Electronics",
  PHONES_AND_COMPUTERS = "Phone and Computers",
  GROCERIES = "Groceries",
  FURNITURES = "Furnitures",
  FASHION = "Fashion",
  BABY_PRODUCTS = "Baby products",
  AUTOMOBILE = "Automobile",
  OTHERS = "Others",
}

export enum UserRole {
  USER = "user",
  COURIER = "courier",
}

export enum MessageType {
  USER = "USER",
  SYSTEM = "SYSTEM",
}
