import React from 'react';

export default function ToastedAlert({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children?: React.ReactNode[] | React.ReactNode;
}) {
  return (
    <div className=" flex w-[100%] justify-between">
      <div>
        <h5 className="">{title}</h5>
        <p className="text-xs">{description}</p>
      </div>
      {/* Button as children because its easier to custom configure its action */}
      {children}
    </div>
  );
}

// Application example
  /*onClick={() =>
      toast.success(
        <ToastedAlert
          description="A Pailot has picked up your package"
          title="Package accepted"
        >
          <Button
            className=" hover:bg-inherit active:bg-inherit"
            fill="ghost"
          >
            View
          </Button>
        </ToastedAlert>
      )
    } */
