import EmptyMessage from '@/components/custom/empty-message';
import { Button } from '@/ui/button';
import { TabsContent } from '@/ui/tabs';
import { ShoppingCart } from 'lucide-react';
import ReceivedDeliveryCard from './received-delivery-card';
import { Delivery } from '@/services/delivery.services';
import { format } from 'date-fns';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { DeliveryFilter } from './delivery-filter';

const getTextBasedOnStatus = (delivery: Delivery) => {
  switch (delivery.deliveryStatus) {
    case DeliveryStatus.ACCEPTED:
      return delivery.acceptedDate;
    case DeliveryStatus.ASSIGNED:
      return delivery.assignedDate;
    case DeliveryStatus.PICKED_UP:
      return delivery.pickupDate;
    case DeliveryStatus.DELIVERED:
      return delivery.deliveryDate;
    default:
      return delivery.createdAt;
  }
};

export default function ReceivedContent({
  deliveryItems,
}: {
  deliveryItems: Delivery[] | undefined;
}) {
  const [filteredDeliveries, setFilteredDeliveries] = useState<Delivery[] | undefined>(
    deliveryItems
  );

  if (!deliveryItems || deliveryItems.length === 0) {
    return (
      <TabsContent value="received">
        <section className="flex flex-col justify-center gap-3">
          <EmptyMessage icon={ShoppingCart} message="Nothing here!">
            <div className="flex flex-col items-center justify-center gap-16">
              <p className="text-center text-xs">
                Your package will be shown here attached with your delivery Vcode
              </p>
              <Link to="/sender">
                <Button className="rounded-lg">Request a Pailot</Button>
              </Link>
            </div>
          </EmptyMessage>
        </section>
      </TabsContent>
    );
  }

  return (
    <TabsContent value="received">
      <section className="flex flex-col justify-center gap-3">
        <div className="flex items-center justify-between">
          <h5 className="font-medium  text-gray-500">Incoming Deliveries</h5>
          <DeliveryFilter deliveries={deliveryItems} onFilterDeliveries={setFilteredDeliveries} />
        </div>
        {filteredDeliveries && filteredDeliveries.length > 0 ? (
          filteredDeliveries.map((item) => {
            const date = getTextBasedOnStatus(item);
            const deliveryTime = format(new Date(date), 'h:mm aaa');
            return (
              <ReceivedDeliveryCard
                deliveryId={item.id}
                deliveryStatus={item.deliveryStatus}
                deliveryTime={deliveryTime}
                imageUrl={item.itemImage}
                itemName={item.itemName}
                key={item.id}
                trackingNumber={item.trackingNumber}
              />
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center gap-16">
            <p className="text-center text-xs">No items found.</p>
          </div>
        )}
      </section>
    </TabsContent>
  );
}
