import { Delivery } from "@/services/delivery.services";
import { CountryDataType } from "@/types";
import { DeliveryStatus } from "@/types/pailot-interfaces";

export function searchCountryListHandler(query : string, array: CountryDataType[]) {
    if (!query) {
      return array;
    } else {
      const filteredArray = array.filter((country) => {
        return country.name.toLowerCase().includes(query.toLowerCase());
      });
      return filteredArray;
    }
  }


export function calculatePercentage(part: number, total: number) {
   if (total === 0) { 
    console.log("The total amount cannot be Zero")
    return 0;
   } return (part / total) * 100
}

export const acceptedDeliveriesHandler = (deliveries: Delivery[]) => {
  return deliveries.filter(
    (delivery) =>
      delivery.deliveryStatus !== DeliveryStatus.CREATED &&
      delivery.deliveryStatus !== DeliveryStatus.CANCELLED &&
      delivery.deliveryStatus !== DeliveryStatus.REJECTED
  );
}

export const successfulDeliveriesHandler = (deliveries: Delivery[]) => {
  return deliveries.filter(
    (delivery) => delivery.deliveryStatus === DeliveryStatus.DELIVERED
  );
}

export const  formatDeliveryDate = (isoDateString: string) => {
  const date = new Date(isoDateString);
  
  // Use toLocaleDateString to format the date

  const formattedDate =  date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  return formattedDate
  // Output Example: 28 Nov 2024
}


export function calculateAverageDeliveryTime(deliveries: Delivery[]) {
  //Basically this function calculates the average difference in time between when the courier picks up the package from the sender and the time that it takes him to return the package to the sender

  // Filter out deliveries with missing pickup or delivery dates
  const validDeliveries = deliveries.filter(
      delivery => delivery.pickupDate && delivery.deliveryDate
  );

  // Calculate seconds between pickup and delivery for each delivery
  const deliveryTimes = validDeliveries.map(delivery => {
      const pickupTime = new Date(delivery.pickupDate);
      const deliveryTime = new Date(delivery.deliveryDate);
      
      // Calculate difference in milliseconds and convert to seconds
      const differenceInSeconds = Math.abs(deliveryTime.getTime() - pickupTime.getTime()) / 1000;
      
      return differenceInSeconds;
  });

  // Calculate average seconds
  const averageSeconds = deliveryTimes.reduce((sum, time) => sum + time, 0) / deliveryTimes.length;

  // Convert average seconds to hours and minutes
  const averageHours = Math.floor(averageSeconds / 3600);
  const averageMinutes = Math.floor((averageSeconds % 3600) / 60);


  // console.log({averageHours, averageMinutes})

  if (typeof averageHours !== 'number' || Number.isNaN(averageMinutes)) {
    return null
  } else {
    return {
      hours: averageHours,
      minutes: averageMinutes
  };
  }
}


export function formatDashboardCalendarDate(dateString: string): string {
  const date = new Date(dateString);
  
  const day = date.getDate().toString();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  
  return `${month} ${day}, ${year}`;
}