import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import FormHeader from './form-header';
import { Props } from '../state-machine/state-machine';
import { Button } from '@/ui/button';
import { UseFormReturn } from 'react-hook-form';
import { DeliveryFormData } from '../delivery-form';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useGetReceiverByUsernameMutation } from '@/services/user.services';
import { ReceiverProfile } from '@/Store/Features/authSlice';
import { Contact2, Check, X, Copy } from 'lucide-react';
import LoaderVariant from '@/components/loader/loader-variant';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/ui/sheet';
import { referReceiver } from '@/assets/images';
import CaseSensitivityModal from './case-sensitivity-modal';
import { AnimatePresence } from 'framer-motion';
import { RootState } from '@/Store';
import { useSelector } from 'react-redux';

function ConfirmReceiverForm({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<DeliveryFormData, any, DeliveryFormData>;
}) {
  const navigate = useNavigate();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [receiverData, setReceiverData] = useState<ReceiverProfile | undefined>();
  const [isSenderSameAsReceiver, setSenderSameAsReceiver] = useState<boolean>(false);
  const [isCaseSensitivityModalVisible, setIsCaseSensitivityModalVisible] =
    useState<boolean>(false);

  const user = useSelector((state: RootState) => state.auth.user);

  const [getReceiverName, { isLoading, isError }] = useGetReceiverByUsernameMutation();

  const {
    formState: { isValid },
    watch,
    register,
  } = form;
  const receiverName = watch('receiverName');

  const handleOnNext = () => {
    if (!receiverData || isSenderSameAsReceiver) return;
    console.log(receiverData);
    form.setValue('receiverUid', receiverData.user.userUid);
    send({ type: 'NEXT' });
  };

  const debounceReceiverName = useMemo(
    () =>
      debounce(async (value) => {
        setSenderSameAsReceiver(false);
        try {
          await getReceiverName({ username: value })
            .unwrap()
            .then((response) => {
              if (response.user.userUid === user?.userUid) {
                setSenderSameAsReceiver(true);
              } else {
                setSenderSameAsReceiver(false);
                setReceiverData(response);
              }
            })
            .catch((error) => {
              console.error(error);
              setReceiverData(undefined);
            });
        } catch (error) {
          console.error(error);
        }
      }, 2000),
    [getReceiverName, user]
  );

  // Trigger debouncing effect when searchValue changes
  useEffect(() => {
    if (receiverName) {
      debounceReceiverName(receiverName);
    }
  }, [receiverName, debounceReceiverName]);

  const copyToClipboard = async () => {
    if (!navigator.clipboard) {
      alert('Clipboard API not supported on this browser.');
      return;
    }

    try {
      await navigator.clipboard.writeText(`https://pailot.app?ref=${user?.username}`);
      setIsLinkCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const caseSensitivityModalToggler = () => {
    setIsCaseSensitivityModalVisible(!isCaseSensitivityModalVisible);
  };

  return (
    <div className="flex h-[100dvh] flex-col justify-between px-4 pb-4 relative ">
      {/* // Case Sensitivity Modal */}
      <AnimatePresence>
        {isCaseSensitivityModalVisible && (
          <CaseSensitivityModal onClose={caseSensitivityModalToggler} />
        )}
      </AnimatePresence>
      <div>
        <FormHeader
          description="Provide the recipient's Pi username"
          onGoBack={() => navigate(-1)}
          step={state.context.step}
          title="Who is the receiver"
        />
        <FormField
          name="receiverName"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Receiver&apos;s Pi username (Case Sensitive)</FormLabel>
              <FormControl>
                <div>
                  <div className="flex items-center gap-2 rounded-md  border-2 border-gray-400 pr-4">
                    <Input
                      className="border-0"
                      type="text"
                      {...field}
                      placeholder="Example: nicolas"
                      {...register('receiverName', {
                        required: "Receiver's Pi username is required",
                      })}
                    />
                    {!isLoading && !isError && !receiverData ? (
                      <Contact2 />
                    ) : isLoading ? (
                      <LoaderVariant />
                    ) : receiverData ? (
                      <Check color="#36e76b" />
                    ) : isError ? (
                      <X />
                    ) : null}
                  </div>
                  {isError ? (
                    <>
                      <FormMessage className="text-red-500">
                        Pi Network username not registered with Pailot.
                      </FormMessage>
                      <button type="button" onClick={caseSensitivityModalToggler}>
                        <p className="text-xs underline text-pi-primary-default">
                          What is case sensitivity?
                        </p>
                      </button>
                    </>
                  ) : null}
                </div>
              </FormControl>
              {isSenderSameAsReceiver && (
                <FormMessage className="text-red-500">
                  You cannot send items to yourself
                </FormMessage>
              )}
              {!isLoading && !isError && !receiverData && !isSenderSameAsReceiver && (
                <p className="text-sm text-gray-600">Username is case sensitive</p>
              )}
            </FormItem>
          )}
        />
      </div>
      {isError ? (
        <div className="flex flex-1 flex-col items-center justify-center gap-8">
          <h4>Invite the receiver to Pailot</h4>
          <p className="w-[80%] text-center text-xs">
            To receive item(s), a pioneer or receiver must connect (signup) Pailot with their Pi
            Network account
          </p>
          <Sheet
            onOpenChange={() => {
              setIsLinkCopied(false);
            }}
          >
            <SheetTrigger className="rounded-xl bg-pi-primary-default px-10 py-3 text-base-white shadow-lg">
              Refer a Receiver
            </SheetTrigger>
            <SheetContent
              className="flex flex-col  items-center rounded-t-3xl bg-base-white px-5 pb-10 pt-5 font-futura"
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="font-futura text-xl font-light  ">
                  Refer a Receiver
                </SheetTitle>
              </SheetHeader>
              <img alt="refer receiver" src={referReceiver} />
              <SheetDescription className="mt-5 justify-center text-center text-sm text-gray-400">
                https://pailot.app?ref={user?.username}
              </SheetDescription>
              <Button
                className="flex items-center justify-center gap-2 rounded-lg"
                onClick={copyToClipboard}
              >
                {isLinkCopied === false ? 'Copy Link' : 'Copied!'}
                {isLinkCopied === false ? <Copy size={17} /> : <Check size={17} />}
              </Button>
            </SheetContent>
          </Sheet>
        </div>
      ) : (
        <Button
          disabled={!isValid || isLoading || isError || !receiverData || isSenderSameAsReceiver}
          onClick={handleOnNext}
          size="large"
        >
          Next
        </Button>
      )}
    </div>
  );
}
export default ConfirmReceiverForm;
