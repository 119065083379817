import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

const checkboxVariants = cva(
  'ring-offset-background focus-visible:ring-ring peer mr-2 size-5 shrink-0 rounded-md border border-input-border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 data-[state=checked]:bg-pi-primary-default data-[state=checked]:text-primary data-[state=checked]:disabled:border-input-border-disabled data-[state=checked]:disabled:bg-primary-disabled data-[state=checked]:disabled:text-primary-content-disabled',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed border-input-border-disabled bg-primary-disabled text-primary-content-disabled',
        false: null,
      },
      'aria-invalid': {
        true: 'border-input-border-error focus:border-input-border-error',
        false: null,
      },
    },
  }
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxVariants>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root ref={ref} className={cn(checkboxVariants(props), className)} {...props}>
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center')}>
      <Check className="size-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
