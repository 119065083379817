// import "./index.css";
import './styles/globals.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Providers } from '@/Store/provider';

import './i18n.ts';
import { SocketProvider } from './contexts/socket-provider';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <SocketProvider>
      <Providers>
        <App />
      </Providers>
    </SocketProvider>
  </React.StrictMode>
);
