export default function Paragraph({
  className,
  title = '',
  children,
}: {
  className?: string;
  title?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={`${className} mx-4 mb-4`}>
      <h4 className="text-lg">{title}</h4>
      <p className="text-sm text-gray-500">{children}</p>
    </div>
  );
}
