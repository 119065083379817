import React from 'react'
import {XCircle} from "lucide-react"
import {motion} from "framer-motion"

interface Props {
    onClose: () => void;
}

const CaseSensitivityModal: React.FC<Props> = ({onClose}) => {
  return (
    <div className='absolute bg-gray-600/90 w-full h-full left-0 z-10 flex items-center justify-center p-4'>
        
        <button onClick={onClose} className='bg-slate-100 absolute top-4 right-4 w-[40px] rounded h-[40px] flex items-center justify-center'>
        <XCircle />
        </button>
        <motion.div 
        initial={{
            scale: 0.5,
            opacity: 0
        }}
        animate={{
            scale: 1,
            opacity: 1
        }}
        exit={{
            scale: 0.5,
            opacity: 0
        }}
        className='text-sm flex flex-col gap-2  bg-slate-100 p-2 rounded-lg' >
        <p className='text-sm' >
              Case sensitivity means that uppercase and lowercase letters are treated as different characters.
            </p>
            <div className="rounded-lg p-4">
              <p className="font-medium mb-2">For example:</p>
              <ul className="space-y-2">
                <li className="font-mono">• "JohnDoe"</li>
                <li className="font-mono">• "johndoe"</li>
                <li className="font-mono">• "JOHNDOE"</li>
              </ul>
              <p className="mt-2">
                These are all considered different usernames because they use different combinations of uppercase and lowercase letters.
              </p>
            </div>
            <p className='text-sm' >
              Make sure to remember exactly how you type the receiver's username, including which letters are uppercase and lowercase.
            </p>
        </motion.div>
      </div>
  )
}

export default CaseSensitivityModal