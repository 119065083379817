/* eslint-disable react-refresh/only-export-components */
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { ProgressActivity } from './progress-activity';

const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring rounded-sm text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-gray-300 disabled:text-gray-50',
  {
    variants: {
      fill: {
        default: 'bg-pi-primary-default text-gray-50 hover:bg-pi-primary-default',
        ghost: 'text-pi-primary-default hover:bg-primary-hover active:bg-primary-press',
        outline:
          'border border-pi-primary-default text-pi-primary-default hover:bg-pi-primary-default hover:text-base-white active:bg-primary-press disabled:border-button-border-disabled',
      },
      size: {
        default: '',
        small: '',
        large: '',
      },
      shape: {
        default: '',
        round: 'rounded-full',
      },
    },
    compoundVariants: [
      {
        size: 'default',
        shape: 'default',
        class: 'h-10  px-4 py-2',
      },
      {
        size: 'small',
        shape: 'default',
        class: 'h-7 min-w-32 px-3 py-1',
      },
      {
        size: 'large',
        shape: 'default',
        class: 'h-14 min-w-32 p-5',
      },
      {
        size: 'default',
        shape: 'round',
        class: 'size-10',
      },
      {
        size: 'small',
        shape: 'round',
        class: 'size-6 p-0.5',
      },
      {
        size: 'large',
        shape: 'round',
        class: 'size-16',
      },
    ],
    defaultVariants: {
      fill: 'default',
      size: 'default',
      shape: 'default',
    },
  }
);

export interface ButtonProps
  extends React.SlotHTMLAttributes<HTMLButtonElement>,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: React.JSX.Element;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, fill, size, shape, loading = false, asChild = false, icon, children, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    // The icon needs to be colored as would the text, depending on the fill type
    // So we need to clone the icon and add the fill class
    const fillClass =
      !fill || fill === 'default' ? 'fill-brand-foreground' : 'fill-primary-content';
    const iconWithClass = icon
      ? React.cloneElement(icon, {
          className: `${fillClass} ${icon.props.className || ''}`,
        })
      : null;

    return (
      <Comp className={cn(buttonVariants({ fill, size, shape, className }))} ref={ref} {...props}>
        {asChild ? (
          children
        ) : (
          <>
            {loading && <ProgressActivity className={`mr-2 animate-spin ${fillClass}`} />}
            {iconWithClass} {children}
          </>
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
