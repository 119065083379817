import { Delivery } from "@/services/delivery.services";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Participant = {
  id: string;
  user: {
    userUid: string;
    username: string;
    profileImgUrl: string | null;
  };
  role: "sender" | "receiver" | "courier";
};

export type Chat = {
  id: string;
  createdAt: string;
  archived: boolean;
  participants: Participant[];
  messages: Message[];
  delivery: Delivery;
  unreadMessageCount: number;
};

export type MessageReadStatus = {
  id: string;
  readAt: Date;
  messageId: string;
  userId: string;
};

export type Message = {
  id: string;
  content: string;
  chatId: string;
  mediaUrl: string | null;
  mediaType: string | null;
  createdAt: string;
  sender: {
    userUid: string;
    username: string;
    profileImgUrl: string | null;
  } | null;
  readStatus: MessageReadStatus[];
  messageType: "USER" | "SYSTEM";
};

export interface ChatState {
  chats: Chat[];
}

const initialState: ChatState = {
  chats: [],
};

// In this case the new messages is a combination of the new loaded messages and the old messages that were already in the chat
// This function will add only the new messages (old conversation as the user scrolls up) to the list of messages
const addOnlyNewMessagesToListOfMessages = (
  currentMessages: Message[],
  currentMessagesAndAdditionalOldMessages: Message[],
) => {
  const oldMessagesIds = currentMessages.map((message) => message.id);
  const uniqueNewMessages = currentMessagesAndAdditionalOldMessages.filter(
    (message) => !oldMessagesIds.includes(message.id),
  );
  return [...uniqueNewMessages, ...currentMessages];
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChats(state, action: PayloadAction<Chat[]>) {
      state.chats = action.payload;
    },
    updateChatMessages(
      state,
      action: PayloadAction<{ chatId: string; messages: Message[] }>,
    ) {
      const chat = state.chats.find(
        (chat) => chat.id === action.payload.chatId,
      );
      if (chat) {
        chat.messages = [
          ...addOnlyNewMessagesToListOfMessages(
            chat.messages,
            action.payload.messages,
          ),
        ];
      }
    },

    addToChatMessages(
      state,
      action: PayloadAction<{ chatId: string; message: Message }>,
    ) {
      const chat = state.chats.find(
        (chat) => chat.id === action.payload.chatId,
      );
      if (chat) {
        chat.messages = [...chat.messages, action.payload.message];
      }
    },

    updateMessageReadStatus(
      state,
      action: PayloadAction<{
        id: string;
        chatId: string;
        messageId: string;
        userId: string;
        readAt: Date;
      }>,
    ) {
      const chat = state.chats.find(
        (chat) => chat.id === action.payload.chatId,
      );
      if (chat) {
        const message = chat.messages.find(
          (message) => message.id === action.payload.messageId,
        );
        if (message) {
          const readStatus = message.readStatus.find(
            (status) => status.userId === action.payload.userId,
          );
          if (readStatus) {
            readStatus.readAt = action.payload.readAt;
          } else {
            message.readStatus.push({
              id: action.payload.id,
              messageId: action.payload.messageId,
              userId: action.payload.userId,
              readAt: action.payload.readAt,
            });
          }
        }
      }
    },

    updateChat(state, action: PayloadAction<Chat>) {
      const chatIndex = state.chats.findIndex(
        (chat) => chat.id === action.payload.id,
      );
      if (chatIndex !== -1) {
        state.chats[chatIndex] = action.payload;
      }
    },

    addChat(state, action: PayloadAction<Chat>) {
      state.chats.push(action.payload);
    },
  },
});

export const {
  setChats,
  updateChatMessages,
  addToChatMessages,
  updateChat,
  addChat,
  updateMessageReadStatus,
} = chatSlice.actions;
export default chatSlice.reducer;
