import {
  SenderProfile,
  User,
  CourierProfile,
  ReceiverProfile,
} from "@/Store/Features/authSlice";
import baseApi from "./base.services";
import {
  SWITCH_PROFILE,
  GET_RECEIVER_BY_USERNAME,
  UPLOAD_PROFILE_IMAGE,
  REMOVE_PROFILE_IMAGE,
  UPDATE_USER_LOCATION,
  UPDATE_ACCESS_TOKEN,
} from "@/constants/config";

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    switchProfile: builder.mutation<
      CourierProfile | SenderProfile,
      { profileName: "sender" | "courier" }
    >({
      query: (body) => ({
        url: SWITCH_PROFILE,
        method: "PUT",
        body,
      }),
    }),
    updateAccessToken: builder.mutation<
      User,
      { accessToken: string }
    >({
      query: (body) => ({
        url: UPDATE_ACCESS_TOKEN,
        method: "PUT",
        body,
      }),
    }),
    getReceiverByUsername: builder.mutation<
      ReceiverProfile,
      { username: string }
    >({
      query: ({ username }) => GET_RECEIVER_BY_USERNAME(username),
    }),
    uploadProfileImage: builder.mutation<User, FormData>({
      query: (formData) => ({
        url: UPLOAD_PROFILE_IMAGE,
        method: "PUT",
        body: formData,
      }),
    }),
    updateUserLocation: builder.mutation<User, { lng: number; lat: number }>({
      query: (formData) => ({
        url: UPDATE_USER_LOCATION,
        method: "PUT",
        body: formData,
      }),
    }),
    removeProfileImage: builder.mutation<void, void>({
      query: () => ({
        url: REMOVE_PROFILE_IMAGE,
        method: "PUT",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSwitchProfileMutation,
  useUpdateAccessTokenMutation,
  useGetReceiverByUsernameMutation,
  useRemoveProfileImageMutation,
  useUploadProfileImageMutation,
  useUpdateUserLocationMutation,
} = userApi;
