import React, { useEffect, useState } from 'react';
import { Trophy, X, CheckCircle2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/ui/button';
import SuccessfulDeliveryItem from './successful-delivery-item';
import { AnimatePresence, motion } from 'framer-motion';
import { Delivery } from '@/services/delivery.services';
import { formatDeliveryDate } from '@/utilities/helperFunctions';

const SuccessfulDeliveries = ({ deliveries, successRate }: { deliveries: Delivery[], successRate: number })=> {
  const [isInfoShowing, setIsInfoShowing] = useState(true);
  const [streak, setStreak] = useState<number>(0);
  const navigate = useNavigate();


  
  useEffect(() => {
    const calculateStreak = () => {
      return lastSevenDays.filter(day => 
        deliveries.some(delivery => formatDeliveryDate(delivery.deliveryDate) === day.date)
      ).length;
    };
    setStreak(calculateStreak());
  }, []);


  const todaysFormattedDate = () => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      year: 'numeric'
    }).replace(',', '');
    return formattedDate
  }

  function getLastSevenDays() {
    const days = [];
    
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      
      days.push({
        day: date.getDate(),
        date: date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })
      });
    }
    
    return days;
  }

  const lastSevenDays = getLastSevenDays()


  return (
    <div className="flex h-screen flex-col bg-gray-100">
      {deliveries.length === 0 ? (
        <div
          className=" flex w-full
      flex-1 flex-col items-center justify-center "
        >
          <div
            className="
      flex flex-1 flex-col items-center justify-center p-4
      "
          >
            <div className="flex flex-col items-center">
              <Trophy size={50} />
              <h4 className="mb-4 mt-6 opacity-80">You have not made a delivery yet</h4>
              <p className="mb-4 text-center text-xs font-semibold opacity-60">
                See pioneers requesting for delivery in your region
              </p>
              <Button
                className="flex w-full items-center justify-center gap-1 whitespace-nowrap border-pi-primary-default text-base-white"
                onClick={() => {
                  navigate('/courier');
                }}
              >
                Pickup a delivery
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <AnimatePresence>
            {isInfoShowing === true && (
              <motion.div
                animate={{ scale: 1 }}
                className="p-4"
                exit={{ scale: 0 }}
                initial={{ scale: 0 }}
                onClick={() => setIsInfoShowing(false)}
              >
                <div className="flex items-center gap-4 rounded-lg border border-dashed border-green-500 bg-base-white p-4 text-gray-500">
                  <p className="text-sm ">
                    Number of deliveries that have been received by the RECEIVER and paid to you
                  </p>
                  <X />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="flex items-center justify-between gap-10 bg-base-white px-4 py-2">
            <div className="flex h-[70px] w-[70px]  flex-col items-center justify-center gap-1 rounded-full border-4 border-green-700 bg-green-100 ">
              <Trophy color="#FF9927" size={18} />
              <span
                className="text-lg font-semibold
              text-gray-600
              "
              >
                {deliveries.length}
              </span>
            </div>
            <div className="flex flex-1 flex-col gap-2  ">
              <div className="flex items-center justify-between text-xs font-semibold">
                <p>{todaysFormattedDate()}</p>
                <p className="text-pi-secondary-default">Success rate: {successRate}%</p>
              </div>
              <div className="flex items-center justify-around px-4  text-xs font-semibold">
              {
                lastSevenDays.map((day: {day: number, date: string}, index: number) => {
                  const todaysDelivery = deliveries.find(
                    (delivery: Delivery) => formatDeliveryDate(delivery.deliveryDate) === day.date
                  );
                                  
                  return ( 
                    <div key={day.day} className="flex flex-col items-center gap-0.5 text-gray-500">
                      {day.day}
                      {lastSevenDays.length - 1 === index && todaysDelivery && streak === 7 ? <Trophy size={12} /> : todaysDelivery ?  <CheckCircle2  size={12} /> : <X size={12} /> }
                    </div>
                  )
                })
              }
              </div>
              <p className="self-end text-[9px] font-semibold opacity-60">
                Last 7 days delivery timeline
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4 p-4">
           {
            deliveries.map((delivery: Delivery) => {
              return  <SuccessfulDeliveryItem delivery={delivery} key={delivery.id}/>
            })
           }            
          </div>
        </>
      )}
    </div>
  );
};

export default SuccessfulDeliveries;
