import React from "react";
import DeliveryItem from "./delivery-item";
import { Delivery } from "@/services/delivery.services";
import { DeliveryStatus } from "@/types/pailot-interfaces";

const AcceptedDeliveries = ({deliveries}: {deliveries: Delivery[]}) => {  

  const isCourierInTransit = (status: string) => {
    if (status === DeliveryStatus.PICKED_UP || status === DeliveryStatus.IN_TRANSIT_TO_RECEIVER ||status === DeliveryStatus.IN_TRANSIT_TO_SENDER  ) {
      return true 
    } else if (status === DeliveryStatus.ACCEPTED) {
      return false
    }
  }

  

  const deliveriesInTransit = deliveries.filter((delivery: Delivery) => isCourierInTransit(delivery.deliveryStatus));
  
  const deliveriesAwaitingDropoff = deliveries.filter((delivery: Delivery) => !isCourierInTransit(delivery.deliveryStatus));

  return (
    <div className="flex-1 ">
      <div
        className="
    flex flex-col gap-2 bg-amber-100 p-4"
      >
        <div
          className=" flex
        items-center justify-between text-base"
        >
          <p>Deliveries ready for transit</p>
          <span
            className="flex h-[20px] w-[20px] 
              items-center justify-center rounded-full   bg-red-700 text-xs text-base-white
              "
          >
            {deliveriesAwaitingDropoff.length}
          </span>
        </div>
        <p className="text-xs">
          Pickup deliveries ready for shipment. Select the closest to
          location you wish to start
        </p>
      </div>
      {deliveriesInTransit.length > 0 && <div
        className="
      flex flex-1 flex-col gap-4 p-4
      "
      >
        <h4 className="text-gray-600">In Transit</h4>

        <div className="flex flex-col gap-4 ">
          {deliveriesInTransit.map((delivery: Delivery) => {
            return (
              <DeliveryItem delivery={delivery} onTransit key={delivery.id}/>
            )
          })}
        </div>
      </div>}
      {deliveriesAwaitingDropoff.length > 0 && <div
        className="
      flex flex-1 flex-col gap-4 p-4
      "
      >
        <h4 className={`text-gray-600  ${deliveriesInTransit.length > 0 && "opacity-30"}`}>Start dropoff</h4>

        <div className="flex flex-col gap-4 ">
          {
            deliveriesAwaitingDropoff.map((delivery: Delivery) => {
              return (
                <DeliveryItem delivery={delivery}  onTransit={false} blur={deliveriesInTransit.length > 0} key={delivery.id}/>
              )
            })
          }
        </div>
      </div>}
    </div>
  );
};

export default AcceptedDeliveries;
