import { useEffect, useState } from 'react';
import { ChevronLeft, ToggleLeft, MessageSquare, ToggleRight } from 'lucide-react';
import { blueLogo, userIcon } from '@/assets/images';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Avatar, AvatarImage } from '@/ui/avatar';
import { IoAlertCircle, MdOutlineLocationOn } from '@/assets/icons';
import { Button } from '@/ui/button';

import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import AdvertSection from './components/request-item/advert-section';
import {
  Delivery,
  useAcceptDeliveryMutation,
  useGetDeliveryByIdQuery,
  useUpdateDeliveryStatusMutation,
} from '@/services/delivery.services';
import { Loader } from '@/components';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { formatDistanceStrict } from 'date-fns';
import { RootState } from '@/Store';
import { updateCourierActiveStatus } from '@/Store/Features/courierSlice';
import { useUpdateCourierAvailabilityMutation } from '@/services/courier.services';
import { useSystemChatMessage } from '@/hooks/use-system-chat-message';

const DeliveryAdvertDetails = () => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isToggleClicked, setIsToggleClicked] = useState(false);
  const [delivery, setDelivery] = useState<Delivery>();

  const [updateCourierAvailabilty, { isLoading: isUpdatingAvailability }] =
    useUpdateCourierAvailabilityMutation();
  const [acceptDelivery, { isLoading: isAcceptingDelivery }] = useAcceptDeliveryMutation();
  const [updateDeliveryStatus, { isLoading: isUpdatingStatus }] = useUpdateDeliveryStatusMutation();

  const isCourierActive = useSelector((state: RootState) => state.courier.isActive);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const { sendSystemMessage } = useSystemChatMessage();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  useEffect(() => {
    const handleUpdateCourierAvailability = async (status: boolean) => {
      try {
        await updateCourierAvailabilty({ isActive: status });
        dispatch(updateCourierActiveStatus(status));
      } catch (error) {
        console.log(error);
      }
    };
    if (isToggleClicked) {
      const timer = setTimeout(() => {
        handleUpdateCourierAvailability(true);
      }, 1000);

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [dispatch, isToggleClicked, updateCourierAvailabilty]);

  const { data: initialDelivery, isLoading: isLoadingDelivery } = useGetDeliveryByIdQuery(
    { id: deliveryId as string },
    {
      skip: !deliveryId,
    }
  );

  useEffect(() => {
    if (initialDelivery) {
      setDelivery(initialDelivery);
    }
  }, [initialDelivery]);

  if (!deliveryId) {
    navigate('/courier');
    return;
  }

  if (isLoadingDelivery || !initialDelivery || !delivery) {
    return <Loader />;
  }

  const handleStartDelivery = async () => {
    try {
      await updateDeliveryStatus({
        deliveryId: initialDelivery.id,
        status: DeliveryStatus.IN_TRANSIT_TO_SENDER,
      });
    } catch (error) {
      console.log(error);
      throw new Error('Failed to start delivery');
    }
  };

  const handleAcceptDelivery = async () => {
    try {
      const delivery = await acceptDelivery({
        deliveryId: initialDelivery.id,
      }).unwrap();
      setDelivery(delivery);
      sendSystemMessage({
        chatId: delivery.chatId,
        content: `Chat created. Delivery for ${delivery.itemName} is assigned to @${currentUser?.username} (courier)`,
      });
      setTimeout(() => {
        sendSystemMessage({
          chatId: delivery.chatId,
          content: `You need to make a payment of ${delivery.transactionAmount}π to start delivery`,
        });
      }, 500); // 500ms delay
      setIsModalVisible(true);
    } catch (error) {
      console.log(error);
      throw new Error('Failed to accept delivery');
    }
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-100">
      <AnimatePresence>
        {isCourierActive === false && (
          <motion.div
            animate={{ opacity: 1 }}
            className="fixed z-10 flex h-full w-full items-center justify-center bg-black/50"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {isUpdatingAvailability === false ? (
              <motion.div
                animate={{ scale: 1, opacity: 1 }}
                className="flex w-[80%] flex-col items-center justify-between gap-4 rounded-lg bg-base-white p-4 py-9"
                exit={{ scale: 0, opacity: 0 }}
                initial={{ scale: 0, opacity: 0 }}
              >
                <h4 className="text-2xl">You&apos;re {isToggleClicked ? 'Offline' : 'Online'}</h4>
                <p className="text-center font-semibold opacity-50 ">
                  {isToggleClicked
                    ? 'You can now accept deliveries'
                    : 'To be able to accept a delivery, you need to be active'}
                </p>

                {isToggleClicked ? (
                  <button
                    className=" scale-70 mt-4 flex items-center gap-1 rounded-xl bg-green-300  px-2 py-0.5 text-xs
              "
                    onClick={() => {
                      setIsToggleClicked(false);
                    }}
                    type="button"
                  >
                    <span className="font-xs font-bold text-green-700">online</span>{' '}
                    <ToggleRight size={14} />
                  </button>
                ) : (
                  <button
                    className=" scale-70 mt-4 flex items-center gap-1 rounded-xl bg-red-300  px-2 py-0.5 text-xs
              "
                    onClick={() => {
                      setIsToggleClicked(true);
                    }}
                    type="button"
                  >
                    <span className="font-xs font-bold text-red-500">offline</span>{' '}
                    <ToggleLeft size={14} />
                  </button>
                )}
              </motion.div>
            ) : (
              <Loader />
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {delivery.deliveryStatus === DeliveryStatus.ASSIGNED && isModalVisible === true && (
        <div className="fixed z-10 flex h-full w-full items-center justify-center">
          <motion.div
            animate={{ opacity: 1, scale: 1 }}
            className="flex w-[80%] flex-col items-center justify-between gap-4 rounded-lg bg-base-white p-4 py-9 min-w-fit mx-2"
            initial={{ opacity: 0, scale: 0 }}
          >
            <h4 className="text-2xl">PENDING</h4>
            <p className="text-center font-semibold opacity-50 ">
              Payment needs to be confirmed by our wallet before delivery starts
            </p>

            <div className="mt-10 flex w-[100%] items-center justify-between gap-4">
              <Button
                className=" flex w-full items-center justify-center gap-2 whitespace-nowrap border-pi-primary-default font-bold text-pi-primary-default"
                fill="outline"
              >
                <Link
                  className="flex items-center justify-center gap-2"
                  state={{ chatId: delivery.chatId }}
                  to={`/chat/${delivery.chatId}`}
                >
                  <MessageSquare />
                  Chat @{delivery.senderProfile.user.username}
                </Link>
              </Button>
              <Button
                className="text-gray-700"
                fill="ghost"
                onClick={() => setIsModalVisible(false)}
              >
                Dismiss
              </Button>
            </div>
          </motion.div>
        </div>
      )}
      <div className="fixed z-10 flex w-full items-center justify-between bg-pi-primary-default px-5 py-3 text-base-white">
        <button
          className="flex items-center space-x-2"
          onClick={() => {
            navigate(-1);
          }}
          type="button"
        >
          <ChevronLeft /> <span>{delivery.itemName}</span>
        </button>
        <div className="rounded-full bg-base-white p-2">
          <img alt="logo" className="h-5 w-5" src={blueLogo} />
        </div>
      </div>
      <div className="h-56">
        {delivery.deliveryStatus === DeliveryStatus.ASSIGNED && (
          <div className="fixed flex w-full items-center justify-center bg-pi-secondary-default bg-opacity-50 px-5 py-3 pt-16 text-sm text-base-white shadow ">
            <IoAlertCircle className="h-8 w-8" />
            <span className="text-center text-sm">
              You will be notified as soon as the sender grants your request
            </span>
          </div>
        )}
        <img alt="logo" className="h-full w-full object-cover" src={delivery.itemImage} />
      </div>
      <div className="relative mb-5 bg-stone-50 px-5 pb-5 shadow-sm">
        <Avatar className="absolute -top-5 h-10 w-10 ">
          <AvatarImage src={userIcon} />
        </Avatar>
        <div className=" pt-5 text-gray-500">@{delivery.senderProfile.user.username}</div>
        <div className="mt-5">
          <div className="text-sm text-gray-500">Overview</div>
          <div className="flex justify-between">
            <div>
              <div>{delivery.itemName}</div>
              <div className="text-sm">
                <span className="font-semibold">ID: </span>
                <span className="text-gray-500">{delivery.trackingNumber}</span>
              </div>
            </div>
            <div className=" flex flex-col items-end">
              <div className="font-semibold text-pi-primary-default">
                {delivery.transactionAmount}π
              </div>
              <div className="text-sm text-gray-500">
                {`Requested ${formatDistanceStrict(delivery.createdAt, new Date(), {
                  addSuffix: true,
                })}`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 flex-1 space-y-4 bg-stone-50 p-5 shadow-sm">
        <div className="text-sm text-gray-500">Location</div>
        <div className="flex items-center justify-between space-x-4">
          <div className="inline-flex w-20 flex-col items-center justify-center bg-gray-100 px-5  py-2 text-gray-500">
            <MdOutlineLocationOn className="  text-2xl   " />
            <div className="text-center text-sm">Pickup Location</div>
          </div>
          <div>{delivery.fromAddress}</div>
        </div>
        <div className="flex items-center justify-between space-x-4">
          <div className="inline-flex w-20 flex-col items-center justify-center bg-gray-100 px-5  py-2 text-gray-500">
            <MdOutlineLocationOn className="  text-2xl   " />
            <div className="text-center text-sm">Dropoff Location</div>
          </div>
          <div>{delivery.toAddress} </div>
        </div>
      </div>

      <div className="space-y-1 bg-stone-50 py-5 shadow-sm">
        <div className="px-5 text-gray-500">{delivery.itemDescription}</div>
        <hr />
      </div>
      <AdvertSection
        delivery={delivery}
        isLoading={isAcceptingDelivery || isUpdatingStatus}
        onAcceptDelivery={handleAcceptDelivery}
        onStartDelivery={handleStartDelivery}
      />
    </div>
  );
};

export default DeliveryAdvertDetails;
