import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Truck, MessageSquare, LayoutDashboard, ScanLine, User } from 'lucide-react';
import { Footer } from '@/ui/footer';
import LayoutButton from './components/LayoutButton';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/Store';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetChatsForUserQuery } from '@/services/chat.services';
import { formatParticipants } from '@/utilities/format';
import { setChats } from '@/Store/Features/chatSlice';

const SENDER_MENU_ITEMS = [
  {
    title: 'Home',
    icon: Home,
    path: '/sender',
  },
  {
    title: 'Delivery',
    icon: Truck,
    path: '/sender/delivery',
  },
  {
    title: 'Chat',
    icon: MessageSquare,
    path: '/chat',
  },
  {
    title: 'Profile',
    icon: User,
    path: '/profile',
  },
];

const COURIER_MENU_ITEMS = [
  {
    title: 'Home',
    icon: Home,
    path: '/courier',
  },
  {
    title: 'Chat',
    icon: MessageSquare,
    path: '/chat',
  },
  {
    title: 'DashBoard',
    icon: LayoutDashboard,
    path: '/courier/dashboard',
  },
  {
    title: 'Vcode',
    icon: ScanLine,
    path: '/courier/verification-code',
  },
  {
    title: 'Profile',
    icon: User,
    path: '/profile',
  },
];

export default function AppLayout({ children }: { children: React.ReactNode[] | React.ReactNode }) {
  const { value } = useLocalStorage();
  const location = useLocation();
  const { data } = useGetChatsForUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    pollingInterval: 60000, //1 minute
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const chats = data.map((chat) => {
        return {
          id: chat.id,
          delivery: chat.deliveryId,
          messages: [],
          participants: formatParticipants(chat.participants),
          createdAt: chat.createdAt,
          archived: chat.archived,
          unreadMessageCount: chat.unreadMessageCount,
        };
      });
      dispatch(setChats(chats));
    }
  }, [data, dispatch]);

  const chats = useSelector((state: RootState) => state.chat.chats);
  const chatsWithUnreadMessages = chats.filter((chat) => chat.unreadMessageCount > 0);

  const MENU_ITEMS = value === 'sender' ? SENDER_MENU_ITEMS : COURIER_MENU_ITEMS;

  return (
    <div className="flex h-[100dvh] flex-col bg-gray-100">
      <div className=" flex flex-1 flex-col overflow-y-auto bg-gray-100 ">{children}</div>
      <Footer className="h-[75px] bottom-0 z-50 flex flex-col items-center justify-center gap-4 border border-t-[1]  bg-base-white">
        <div className=" flex w-[100%] justify-around">
          {MENU_ITEMS.map((item) => {
            const { title, icon: Icon, path } = item;
            const isActive = location.pathname === path;

            return (
              <Link key={title} to={path} className="relative">
                <AnimatePresence>
                  {title === 'Chat' && chatsWithUnreadMessages.length > 0 && (
                    <motion.div
                      initial={{
                        scale: 0,
                        opacity: 0,
                      }}
                      animate={{
                        scale: 1,
                        opacity: 1,
                      }}
                      exit={{
                        scale: 0,
                        opacity: 0,
                      }}
                      className="absolute -top-[10px] -right-[10px] bg-red-500 h-[20px] w-[20px] flex items-center justify-center rounded-full text-xs text-slate-100"
                    >
                      {chatsWithUnreadMessages.length}
                    </motion.div>
                  )}
                </AnimatePresence>
                <LayoutButton path={path}>
                  <Icon fill={isActive ? '#1E04BB' : 'none'} />
                  <span className="">{title}</span>
                </LayoutButton>
              </Link>
            );
          })}
        </div>
      </Footer>
    </div>
  );
}
