import React from "react";

interface Props {
  color?: string;
}

const LoaderVariant: React.FC<Props> = ({ color }) => {
  return (
    <div
      className={`${color ? color : "border-slate-950/10"} h-[25px] w-[25px] animate-spin rounded-full border-4 border-l-transparent `}
    />
  );
};

export default LoaderVariant;
