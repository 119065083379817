import { useEffect, useState, useMemo } from 'react';
import { ChevronDown, Ban, Bike, Truck, Circle } from 'lucide-react';
import { Car, Drone, Motorbike, Tricycle } from '@/assets/icons';

import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/ui/sheet';
import { Button } from '@/ui/button';
import LocationSelectRadio from './location-select-radio';
import { CountryDataType, StateDataType } from '@/types';
import MODCheckbox from './mod-checkbox';
import { Props } from './state-machine/courier-setup-machine';
import FormHeader from '@/components/delivery-form/components/form-header';
import { FormField, FormItem, FormMessage } from '@/ui/form';
import { RadioGroup } from '@/ui/radio-group';
import { UseFormReturn } from 'react-hook-form';
import { CourierSetupForm } from './setup-form';
import { Search } from 'lucide-react';
import { searchCountryListHandler } from '@/utilities/helperFunctions';

const SetupOptions = ({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<CourierSetupForm, any, CourierSetupForm>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //list of all locations
  const [countries, setCountries] = useState<CountryDataType[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<CountryDataType[]>(countries)
  const [states, setStates] = useState<StateDataType[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  const { watch } = form;

  const country = watch('country');
  const selectedStateInCountry = watch('state');
  const selectedCity = watch('city');
  const preferredModeOfDelivery = watch('preferredModeOfDelivery');

  //selected user locations
  const selectedCountry = useMemo(() => {
    const selectedCountry = countries.find((c) => c.name === country);
    return selectedCountry || { name: '', flag: '', initials: '' };
  }, [countries, country]); 

  const selectedState = useMemo(() => {
    const selectedStates = states.find((s) => s.name === selectedStateInCountry);
    return selectedStates || { name: '', flag: '', initials: '' };
  }, [selectedStateInCountry, states]);

  const modesOfDelivery = [
    {
      name: 'Bicycle',
      icon: <Bike />,
      value: 'Bicycle',
    },
    {
      name: 'Car',
      icon: <img alt="car" src={Car} />,
      value: 'Car',
    },
    {
      name: 'Motorbike',
      icon: <img alt="car" src={Motorbike} />,
      value: 'Motorbike',
    },
    {
      name: 'Drone',
      icon: <img alt="car" src={Drone} />,
      value: 'Drone',
    },
    {
      name: 'Tricycle',
      icon: <img alt="car" src={Tricycle} />,
      value: 'Tricycle',
    },
    {
      name: 'Truck',
      icon: <Truck />,
      value: 'Truck',
    },
  ];

  const selectedCountryHandler = (country: string) => {
    const chosenCountry = countries.find((c) => c.name === country);
    if (chosenCountry) {
      form.setValue('country', chosenCountry.name, { shouldDirty: true });
      form.setValue('state', '', { shouldDirty: true });
      form.setValue('city', '', { shouldDirty: true });
      setCities([]);
    }
  };

  const selectedStateHandler = (state: string) => {
    const chosenState = states.find((s) => s.name === state);
    if (chosenState) {
      form.setValue('state', chosenState.name, { shouldDirty: true });
      form.setValue('city', '', { shouldDirty: true });
      setCities([]);
    }
  };

  const selectedCityHandler = (city: string) => {
    form.setValue('city', city, { shouldDirty: true });
  };

  const isNextDisabled = () => {
    return !preferredModeOfDelivery || !country || !selectedStateInCountry || !selectedCity;
  };

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      const response = await fetch('https://restcountries.com/v3.1/all?fields=name,flags,cca2');
      const data = await response.json();

      const countries = data.map((country: any) => {
        return {
          name: country.name.common,
          flag: country.flags?.svg,
          initials: country.cca2,
        };
      });
      countries.sort((a: CountryDataType, b: CountryDataType) => a.name.localeCompare(b.name));
      setCountries(countries);
      setIsLoading(false);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry.name === '') return;
    const fetchStates = async () => {
      setIsLoading(true);
      const headers = new Headers();
      headers.append('X-CSCAPI-KEY', 'VFhQTW9nbDFBeldnQ1RtcERZQkMybmxyUkxYNTkwSUF3TkdvV1VPaw==');

      const requestOptions = {
        method: 'GET',
        headers: headers,
      };
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${selectedCountry.initials}/states`,
        requestOptions
      );
      const data = await response.json();
      const states = data.map((state: any) => {
        return {
          name: state.name,
          initials: state.iso2,
        };
      });
      states.sort((a: StateDataType, b: StateDataType) => a.name.localeCompare(b.name));
      setStates(states);
      setIsLoading(false);
    };
    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState.name === '' || selectedCountry.name === '') return;
    const fetchCities = async () => {
      setIsLoading(true);

      const headers = new Headers();
      headers.append('X-CSCAPI-KEY', 'VFhQTW9nbDFBeldnQ1RtcERZQkMybmxyUkxYNTkwSUF3TkdvV1VPaw==');

      const requestOptions = {
        method: 'GET',
        headers: headers,
      };
      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/${selectedCountry.initials}/states/${selectedState.initials}/cities`,
        requestOptions
      );
      const data = await response.json();
      const cities = data.map((city: any) => {
        return city.name;
      });
      cities.sort((a: string, b: string) => a.localeCompare(b));
      setCities(cities);
      setIsLoading(false);
    };
    fetchCities();
  }, [selectedState, selectedCountry]);

  return (
    <div className="flex h-screen flex-col gap-0.5">
      <div className="flex flex-1 flex-col gap-4 overflow-auto p-4">
        <FormHeader
          description="Show one primary mode of delivery which will be used to determine your hiring opportunity"
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Mode and Location"
        />
        <h5 className="text-pi-gray-default mb-4 text-xl">Mode of delivery</h5>
        <Sheet>
          <SheetTrigger
            className="flex w-full
              items-center justify-between rounded-lg border border-gray-500 px-4 py-4
              "
          >
            <span className="flex gap-4">
              {!preferredModeOfDelivery ? (
                <Ban />
              ) : (
                modesOfDelivery.filter(
                  (m: { name: string; icon: any }) => m.name === preferredModeOfDelivery
                )[0]?.icon
              )}
              {!preferredModeOfDelivery ? 'Select' : preferredModeOfDelivery}
            </span>
            <ChevronDown />
          </SheetTrigger>
          <SheetContent
            className="rounded-t-3xl bg-base-white  px-5 pb-10 pt-5 font-futura "
            side="bottom"
          >
            <SheetHeader>
              <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />

              <SheetTitle className="w-full text-left  font-futura text-xl font-light">
                <div className="mb-5 flex flex-col gap-0.5 text-right">
                  <p className="flex items-center gap-1  text-sm">
                    <Circle size="12px" />
                    <span>
                    Main delivery mode (one choice)
                    </span>{' '}
                  </p>
                  <p className="flex items-center gap-1  text-sm">
                    <svg
                      className="lucide lucide-squircle"
                      fill="none"
                      height="14"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9-9 9-9-1.8-9-9 1.8-9 9-9" />
                    </svg>
                    <span>
                    Secondary delivery mode (multiple choices)
                    </span>{' '}
                  </p>
                </div>
                Modes of Delivery
              </SheetTitle>
            </SheetHeader>
            <div className="mt-5 flex flex-col justify-center  gap-4 text-center ">
              <FormField
                name="modesOfDelivery"
                render={() => (
                  <FormItem>
                    <RadioGroup
                      defaultValue={preferredModeOfDelivery}
                      onValueChange={(value) =>
                        form.setValue('preferredModeOfDelivery', value, {
                          shouldDirty: true,
                        })
                      }
                    >
                      {modesOfDelivery.map((mode) => (
                        <MODCheckbox item={mode} key={mode.name} />
                      ))}
                    </RadioGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <SheetClose asChild>
              <Button
                className={`mt-8 flex w-full items-center justify-center  text-lg 
                  ${preferredModeOfDelivery ? 'border-2 border-pi-primary-default' : 'bg-gray-300'}
                  `}
                disabled={!preferredModeOfDelivery}
                fill="default"
              >
                Save
              </Button>
            </SheetClose>
          </SheetContent>
        </Sheet>
        {/* /> */}
        <h3
          className="is-h4 w-[70%]
              text-2xl
              font-medium text-pi-primary-default
              "
        >
          Areas you will be very active for delivery
        </h3>

        {/* ///// COUNTRY ///// */}
        <div>
          <h5
            className="
          text-pi-gray-default
          mb-2 text-xl
          "
          >
            Country
          </h5>
          <Sheet>
            <SheetTrigger
              className={`flex w-full
              items-center justify-between rounded-lg border border-gray-500 px-4 ${
                selectedCountry.name === '' ? 'py-4' : 'py-2'
              }
              `}
              disabled={countries.length === 0}
            >
              <span className="flex items-center gap-4">
                {selectedCountry.name === '' ? (
                  <Ban />
                ) : (
                  <img
                    alt="flag"
                    className="
                    aspect-square
                    w-10
                    rounded-full
                    bg-gray-500
                    object-cover
                    "
                    src={selectedCountry.flag}
                  />
                )}
                {isLoading
                  ? 'Loading'
                  : selectedCountry.name === ''
                  ? 'None'
                  : selectedCountry.name}
              </span>
              <ChevronDown />
            </SheetTrigger>
            <SheetContent
              className="rounded-t-3xl bg-base-white  px-5 pb-10 pt-5 font-futura h-[95dvh]"
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-2 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="w-full text-left font-futura text-xl font-light">
                  Choose a Country
                </SheetTitle>
                <div className='items-center flex  gap-2 rounded-lg border-2 border-gray-300 px-2 overflow-hidden'>
                  <input type="text" name="Country" id="country" placeholder='Search Country...' className='flex-1 h-[30px] text-base focus:outline-none' 
                  onChange={(e) => {
                    const value = e.target.value
                    const filteredList  = searchCountryListHandler(value, countries);
                    setFilteredCountries(filteredList);
                  }}
                  />
                 
                  <Search className='stroke-gray-500'/>

                </div>
              </SheetHeader>
              <div className="mt-1 flex  flex-col justify-center  gap-4 text-center ">
                <div
                  className="
                  h-[62dvh]
                  overflow-auto
                "
                >
                  <FormField
                    name="country"
                    render={() => {
                      if (filteredCountries.length > 0) {
                       return (
                        <>
                         <FormItem>
                        {filteredCountries.map((country: CountryDataType) => (
                          <LocationSelectRadio
                            item={country.name}
                            key={country.name}
                            onUpdateSelectedItem={selectedCountryHandler}
                            selectedItem={selectedCountry.name}
                          />
                        ))}
                        <FormMessage />
                      </FormItem>
                        </>
                       )
                      } else {
                        return (
                          <>
                           <FormItem>
                        {countries.map((country: CountryDataType) => (
                          <LocationSelectRadio
                            item={country.name}
                            key={country.name}
                            onUpdateSelectedItem={selectedCountryHandler}
                            selectedItem={selectedCountry.name}
                          />
                        ))}
                        <FormMessage />
                      </FormItem>
                          </>
                        )
                      }
                    }}
                  />
                </div>
              </div>
              <SheetClose asChild>
                <Button
                  className={`mt-4 flex w-full items-center justify-center  text-lg 
                  ${
                    selectedCountry.name !== ''
                      ? 'border-2 border-pi-primary-default '
                      : 'bg-gray-300'
                  }
                  `}
                  disabled={selectedCountry.name === ''}
                  fill="default"
                >
                  Save
                </Button>
              </SheetClose>
            </SheetContent>
          </Sheet>
        </div>

        {/* /// STATE //// */}
        <div>
          <h5
            className="
          text-pi-gray-default
          mb-2 text-xl
          "
          >
            State
          </h5>
          <Sheet>
            <SheetTrigger
              className={`flex w-full
              items-center justify-between rounded-lg border border-gray-500 px-4 py-4 
              `}
              disabled={states.length === 0}
            >
              <span className="flex items-center gap-4">
                {isLoading
                  ? 'Loading'
                  : selectedState.name === ''
                  ? states.length === 0
                    ? 'No States'
                    : 'Not Selected'
                  : selectedState.name}
              </span>
              <ChevronDown />
            </SheetTrigger>
            <SheetContent
              className="rounded-t-3xl bg-base-white  px-5 pb-10 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="w-full text-left font-futura text-xl font-light">
                  Choose a State
                </SheetTitle>
              </SheetHeader>
              <div className="mt-5 flex flex-col justify-center  gap-4 text-center ">
                <div
                  className="
                  h-96
                  overflow-auto
                "
                >
                  <FormField
                    name="country"
                    render={() => (
                      <FormItem>
                        {states.map((state: StateDataType) => (
                          <LocationSelectRadio
                            item={state.name}
                            key={state.name}
                            onUpdateSelectedItem={selectedStateHandler}
                            selectedItem={selectedState.name}
                          />
                        ))}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <SheetClose asChild>
                <Button
                  className={`mt-8 flex w-full items-center justify-center  text-lg 
                  ${
                    selectedState.name !== ''
                      ? 'border-2 border-pi-primary-default '
                      : 'bg-gray-300'
                  }
                  `}
                  disabled={selectedState.name === ''}
                  fill="default"
                >
                  Save
                </Button>
              </SheetClose>
            </SheetContent>
          </Sheet>
        </div>

        {/* //// CITY ///// */}
        <div>
          <h5
            className="
          text-pi-gray-default
          mb-2 text-xl
          "
          >
            City
          </h5>
          <Sheet>
            <SheetTrigger
              className={`flex w-full
              items-center justify-between rounded-lg border border-gray-500 px-4 py-4 
              `}
              disabled={cities.length === 0}
            >
              <span className="flex items-center gap-4">
                {isLoading
                  ? 'Loading'
                  : selectedCity === ''
                  ? cities.length === 0
                    ? 'No Cities'
                    : 'Not Selected'
                  : selectedCity}
              </span>
              <ChevronDown />
            </SheetTrigger>
            <SheetContent
              className="rounded-t-3xl bg-base-white  px-5 pb-10 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="w-full text-left font-futura text-xl font-light">
                  Choose a City
                </SheetTitle>
              </SheetHeader>
              <div className="mt-5 flex flex-col justify-center  gap-4 text-center ">
                <div
                  className="
                  h-96
                  overflow-auto
                "
                >
                  <FormField
                    name="country"
                    render={() => (
                      <FormItem>
                        {cities.map((city: string) => (
                          <LocationSelectRadio
                            item={city}
                            key={city}
                            onUpdateSelectedItem={selectedCityHandler}
                            selectedItem={selectedCity}
                          />
                        ))}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <SheetClose asChild>
                <Button
                  className={`mt-8 flex w-full items-center justify-center  text-lg 
                  ${selectedCountry.name ? 'border-2 border-pi-primary-default' : 'bg-gray-300'}
                  `}
                  disabled={!selectedCountry.name}
                  fill="default"
                >
                  Save
                </Button>
              </SheetClose>
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <div className="p-4">
        <Button
          className={` flex w-full items-center justify-center text-lg
          ${
            !isNextDisabled()
              ? 'border-2 border-pi-primary-default bg-pi-primary-default '
              : 'bg-gray-300'
          }
          `}
          disabled={isNextDisabled()}
          fill="default"
          onClick={() => send({ type: 'NEXT' })}
          size="large"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetupOptions;
