import { Button } from '@/ui/button';
import React from 'react';
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  receiversUsername: string;
  deliveryID: string;
  isCustomDelivery: boolean;
}

const DeliverySubmitResult: React.FC<Props> = ({
  receiversUsername,
  deliveryID,
  isCustomDelivery,
}) => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/sender');
  };
  return (
    <div className="flex h-[100dvh] w-[100vw] flex-col gap-4 p-4">
      <div className="flex justify-end">
        <Button fill="ghost" onClick={goToHome}>
          Done
        </Button>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
        <motion.div
          animate={{ scale: 1, opacity: 1 }}
          className="my-4 flex items-center justify-center"
          initial={{ scale: 0, opacity: 0 }}
        >
          <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-50 ">
            <Check color="#039855" size={45} />
          </div>
        </motion.div>
        <h4 className="text-center text-xl">Delivery Submitted </h4>
        <p
          className="w-[70%]
      self-center text-center text-sm text-gray-600
      "
        >
          You will be notified as soon as {isCustomDelivery ? 'a' : 'the'} Pailot accepts your
          request
        </p>
        <div className=" w-[100%]">
          <div
            className="flex items-center justify-between
        border-b border-dotted border-gray-100 p-2
        "
          >
            <span>Receiver</span>
            <span>@{receiversUsername}</span>
          </div>
        </div>
      </div>
      <div
        className="
      flex w-[100%]  items-center justify-center gap-4
      "
      >
        <Link
          className="w-[100%]"
          state={{
            deliveryId: deliveryID,
          }}
          to="/receiver/timeline"
        >
          <Button className="w-[100%]">View Delivery</Button>
        </Link>
      </div>
    </div>
  );
};

export default DeliverySubmitResult;
