import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import { Label } from '@/ui/label';
import { Bike, Car, X, Footprints, Truck } from 'lucide-react';
import StackedLineChart from '@/icons/StackedLineChart';
import { Dispatch, SetStateAction } from 'react';

const modes = [
  {
    value: 'motorbike',
    label: 'Motorbike',
    Icon: Bike,
  },
  {
    value: 'car',
    label: 'Car',
    Icon: Car,
  },
  {
    value: 'truck',
    label: 'Truck',
    Icon: Truck,
  },
  {
    value: 'walk',
    label: 'Walk',
    Icon: Footprints,
  },
];
interface Props {
  // eslint-disable-next-line no-unused-vars
  stateUpdate: Dispatch<SetStateAction<string[]>>;
}

const DeliveryMode: React.FC<Props> = ({ stateUpdate }) => {
  return (
    <FormField
      name="modeOfDelivery"
      render={({ field }) => (
        <FormItem>
          <FormLabel required>Mode of Delivery</FormLabel>
          <div className="flex gap-2 align-center py-2 px-1 overflow-hidden relative">
           <div className='flex-1'>
           <FormControl>
              <Input
                checked={field.value.length === modes.length}
                className="sr-only" // visually hide the checkbox
                id="all"
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    field.onChange([...new Set(modes.map((mode) => mode.value))]);
                    stateUpdate([...new Set(modes.map((mode) => mode.value))]);
                  } else {
                    field.onChange([]);
                    stateUpdate([]);
                  }
                }}
                type="checkbox"
              />
            </FormControl>
            <Label
              className={`${
                field.value.length === modes.length ? 'text-pi-primary-default' : 'text-gray-700'
              } relative flex  cursor-pointer flex-col items-center justify-center rounded bg-gray-200 py-2 text-xs font-medium capitalize leading-tight shadow-md transition  duration-150 ease-in-out`}
              htmlFor="all"
            >
              <StackedLineChart
                className={`${
                  field.value.length === modes.length
                    ? 'stroke-pi-primary-default'
                    : 'stroke-gray-700'
                } h-4 w-4`}
              />
              <span>All</span>
            </Label>
           </div>
            {modes.map((mode) => (
              <div key={mode.value} className='flex-1'>
                <FormControl>
                  <Input
                    {...field}
                    checked={field.value.includes(mode.value)}
                    className="sr-only" // visually hide the checkbox
                    id={mode.value}
                    onChange={(e) => {
                      const value = e.target.checked
                        ? [...new Set([...field.value, e.target.value])]
                        : field.value.filter((v: string) => e.target.value !== v);

                      field.onChange(value);
                      stateUpdate(value);
                    }}
                    type="checkbox"
                    value={mode.value}
                  />
                </FormControl>
                <Label
                  className={`${
                    field.value.includes(mode.value) ? 'text-pi-primary-default' : 'text-gray-700'
                  } relative flex  cursor-pointer flex-col items-center justify-center rounded bg-gray-200 py-2 text-xs font-medium capitalize leading-tight shadow-md transition  duration-150 ease-in-out`}
                  htmlFor={mode.value}
                >
                  {field.value.includes(mode.value) && (
                    <div
                    className=' absolute -right-1 -top-1 bg-black rounded-full p-[2px]'
                    >
                      <X className="stroke-red-500" size={12} />
                    </div>
                  )}
                  <mode.Icon
                    className={`${
                      field.value.includes(mode.value)
                        ? 'stroke-pi-primary-default'
                        : 'stroke-gray-700'
                    } h-4 w-4`}
                  />
                  <span>{mode.label}</span>
                </Label>
              </div>
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default DeliveryMode;
