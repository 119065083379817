import React, { useEffect, useState } from 'react';
import { Button } from '@/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/ui/sheet';
import { Check, X } from 'lucide-react';
import { Loader } from '@/components';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { useNavigate } from 'react-router-dom';
import { useSystemChatMessage } from '@/hooks/use-system-chat-message';
import {
  Delivery,
  useVerifyReceiverDeliveryCodeMutation,
  useVerifySenderDeliveryCodeMutation,
} from '@/services/delivery.services';
import { userIcon } from '@/assets/images';
import VcodeSheetStatusContent from './v-code-sheet-content';

enum BottomSheetState {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  NONE = 'none',
}

const OTPInput = ({
  otp,
  handleChange,
}: {
  otp: string[];
  handleChange: (e: HTMLInputElement, i: number) => void;
}) => (
  <div className="mt-4 flex w-full items-center justify-between gap-2">
    {otp.map((data, index) => (
      <input
        key={`otp-${index}`}
        className={`h-[10vw] w-[10vw] border border-base-black/[.2] text-center text-pi-primary-default ${
          otp[index] === '' && 'opacity-30'
        } text-xl font-medium `}
        inputMode="numeric"
        maxLength={1}
        value={data}
        onChange={(e) => handleChange(e.target, index)}
        onFocus={(e) => e.target.select()}
      />
    ))}
  </div>
);

const VCodeSection = ({
  delivery,
  refetchDelivery,
}: {
  delivery: Delivery;
  refetchDelivery: () => void;
}) => {
  const { sendSystemMessage } = useSystemChatMessage();
  const navigate = useNavigate();
  const [verifyPickupCode, { isLoading: isVerifyingPickupCode }] =
    useVerifySenderDeliveryCodeMutation();
  const [verifyDeliveryCode, { isLoading: isVerifyingDeliveryCode }] =
    useVerifyReceiverDeliveryCodeMutation();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [bottomSheetState, setBottomSheetState] = useState<BottomSheetState>(BottomSheetState.NONE);

  const isSenderView = delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER;
  const isReceiverView =
    delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER ||
    delivery.deliveryStatus === DeliveryStatus.PICKED_UP;

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value;

    // Allow only numeric input or empty value
    if (value === '' || !isNaN(Number(value))) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value; // Update the current value
      setOtp(updatedOtp);

      if (value) {
        // Move focus forward if a number is entered and not the last input
        if (element.nextSibling instanceof HTMLInputElement) {
          element.nextSibling.focus();
        }
      } else if (!value && index > 0) {
        // Move focus backward if input is cleared
        if (element.previousSibling instanceof HTMLInputElement) {
          element.previousSibling.focus();
        }
      }
    }
  };

  const handleVerifyCode = async () => {
    const enteredOTP = otp.join('');
    try {
      const verifyFn = isSenderView ? verifyPickupCode : verifyDeliveryCode;
      const response = await verifyFn({ deliveryId: delivery.id, code: +enteredOTP }).unwrap();
      if (response.status === 'success') {
        refetchDelivery();
        setOtp(new Array(6).fill(''));
        sendSystemMessage({
          chatId: delivery.chatId,
          content: isSenderView
            ? `Package picked up from @${delivery.senderProfile.user.username}. Courier is on the way to the receiver.`
            : `Package delivered to @${delivery.receiverProfile.user.username} (receiver). This chat is now archived. Thank you for using Pailot! The chat is now archived.`,
        });
      }
      setBottomSheetState(response.status as BottomSheetState);
    } catch {
      setBottomSheetState(BottomSheetState.ERROR);
    }
  };

  useEffect(() => {
    if (
      ![DeliveryStatus.DELIVERED, DeliveryStatus.RETURNED, isReceiverView, isSenderView].some(
        Boolean
      )
    ) {
      navigate('/courier/verification-code');
    }
  }, [isReceiverView, isSenderView, delivery, navigate]);

  return (
    <>
      {/* Delivery Completed or Returned States */}
      {(delivery.deliveryStatus === DeliveryStatus.DELIVERED ||
        delivery.deliveryStatus === DeliveryStatus.RETURNED) && (
        <div className="sticky bottom-0 p-4">
          <Button className="w-full border-pi-primary-default text-base-white opacity-40">
            {delivery.deliveryStatus === DeliveryStatus.DELIVERED
              ? 'Delivery Completed'
              : 'Package Returned'}
          </Button>
        </div>
      )}
      {delivery.deliveryStatus !== DeliveryStatus.DELIVERED &&
        delivery.deliveryStatus !== DeliveryStatus.RETURNED && (
          <Sheet>
            {/* Trigger Button */}
            <SheetTrigger className="sticky bottom-0 p-4">
              <Button className="w-full border-pi-primary-default text-base-white">
                Verify {isSenderView ? "Sender's" : "Receiver's"} Code
              </Button>
            </SheetTrigger>

            {/* OTP Input Sheet */}
            {bottomSheetState === BottomSheetState.NONE && (
              <SheetContent
                className="rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura"
                side="bottom"
              >
                <SheetHeader>
                  <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                  <SheetTitle className="text-xl font-light">
                    Enter {isSenderView ? "Sender's" : "Receiver's"} Confirmation Code
                  </SheetTitle>
                </SheetHeader>
                <SheetDescription className="mt-5 text-center text-sm text-gray-400">
                  Code becomes active when your Pailot is closer to the pickup location
                </SheetDescription>
                <OTPInput otp={otp} handleChange={handleChange} />
                <Button
                  className="mt-2 w-full text-base-white"
                  disabled={
                    otp.some((d) => d === '') || isVerifyingPickupCode || isVerifyingDeliveryCode
                  }
                  loading={isVerifyingPickupCode || isVerifyingDeliveryCode}
                  onClick={handleVerifyCode}
                >
                  Verify Code
                </Button>
              </SheetContent>
            )}

            {/* Loading States */}
            {(isVerifyingDeliveryCode || isVerifyingPickupCode) && (
              <SheetContent
                className="rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura"
                side="bottom"
              >
                <SheetHeader>
                  <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                  <SheetTitle className="font-futura text-xl font-light">
                    Confirming Package
                  </SheetTitle>
                </SheetHeader>
                <div className="flex h-[30dvh] w-full items-center justify-center">
                  <Loader className="bg-transparent" />
                </div>
                <Button
                  className="text-m mt-2 w-full items-center justify-center border-2 border-pi-primary-default text-base-white"
                  fill="default"
                >
                  Please Wait...
                </Button>
              </SheetContent>
            )}

            {/* Success State */}
            {bottomSheetState === BottomSheetState.SUCCESS && (
              <VcodeSheetStatusContent
                title="Code Verified"
                description={
                  isSenderView
                    ? `Package pickup for @${delivery.senderProfile.user.username}`
                    : `Package delivered to @${delivery.receiverProfile.user.username}`
                }
                icon={<Check color="#039855" size={45} />}
                iconBg="bg-green-100"
                isClosable
                images={[
                  { src: delivery.itemImage, alt: 'Package Image' },
                  {
                    src:
                      (isSenderView
                        ? delivery.senderProfile.user.profileImgUrl
                        : delivery.receiverProfile.user.profileImgUrl) || userIcon,
                    alt: isSenderView ? 'Sender Profile' : 'Receiver Profile',
                  },
                ]}
              />
            )}

            {/* Error State */}
            {bottomSheetState === BottomSheetState.ERROR && (
              <VcodeSheetStatusContent
                title="Code Invalid"
                description={`Package did not match with this ${
                  isSenderView ? 'Sender' : 'Receiver'
                }`}
                icon={<X color="#cc0000" size={45} />}
                iconBg="bg-red-50"
                primaryActionText="Try Again"
                onPrimaryAction={() => {
                  setBottomSheetState(BottomSheetState.NONE);
                  setOtp(new Array(6).fill(''));
                }}
                images={[
                  { src: delivery.itemImage, alt: 'Package Image' },
                  {
                    src:
                      (isSenderView
                        ? delivery.senderProfile.user.profileImgUrl
                        : delivery.receiverProfile.user.profileImgUrl) || userIcon,
                    alt: isSenderView ? 'Sender Profile' : 'Receiver Profile',
                  },
                ]}
              />
            )}
          </Sheet>
        )}
    </>
  );
};

export default VCodeSection;
