import AppLayout from '../app-layout/app-layout';
import { ListFilter, ChevronRight, StickyNote } from 'lucide-react';
import { Button } from '@/ui/button';
import { Card } from '@/ui/card';
import { useNavigate } from 'react-router-dom';
import { Delivery, useGetDeliveriesByCourierQuery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Loader } from '@/components';
import { format } from 'date-fns';

const DeliveryCard = ({
  delivery,
  onClick,
  type,
}: {
  delivery: Delivery;
  onClick: () => void;
  type: 'accepted' | 'completed';
}) => (
  <Card
    className="flex w-full flex-row gap-1 space-x-3 rounded-lg bg-base-white p-2 text-sm shadow-sm"
    key={delivery.id}
    onClick={onClick}
  >
    {/* Image Section */}
    <div className="flex w-2/12 items-center justify-center overflow-hidden rounded-lg border">
      <img alt={delivery.itemName} className="object-cover" src={delivery.itemImage} />
    </div>

    {/* Details Section */}
    <div className="flex flex-1 flex-col">
      <div className="flex items-center justify-between">
        <p>{delivery.itemName}</p>
        {type === 'accepted' && (
          <span className="flex items-center gap-1 text-xs font-semibold text-pi-primary-default">
            Verify Package
            <ChevronRight size={12} />
          </span>
        )}
        {type === 'completed' && (
          <span className="text-xs opacity-40">
            {format(new Date(delivery.deliveryDate), 'h:mm aaa')}
          </span>
        )}
      </div>
      <div className="flex items-center justify-between">
        <span className="text-sm opacity-70">#{delivery.trackingNumber}</span>
        {type === 'accepted' && (
          <span className="text-xs opacity-40">
            {format(new Date(delivery.acceptedDate), 'h:mm aaa')}
          </span>
        )}
      </div>
      <span
        className={`w-min text-nowrap rounded px-2 py-1 text-xs ${
          delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER
            ? 'bg-purple-100 text-pi-primary-default'
            : delivery.deliveryStatus === DeliveryStatus.PICKED_UP ||
              delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
            ? 'bg-amber-100 text-pi-secondary-default'
            : 'bg-green-100 text-green-800'
        }`}
      >
        {delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER
          ? 'Pickup'
          : delivery.deliveryStatus === DeliveryStatus.PICKED_UP ||
            delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
          ? 'Drop off'
          : 'Delivered'}
      </span>
    </div>
  </Card>
);

const NoDeliveries = ({ message, onAction }: { message: string; onAction: () => void }) => (
  <div className="flex flex-1 flex-col items-center justify-center p-4">
    <StickyNote size={50} />
    <h4 className="mb-4 mt-6 opacity-80">{message}</h4>
    <Button
      className="flex w-full items-center justify-center gap-1 whitespace-nowrap border-pi-primary-default text-base-white"
      onClick={onAction}
    >
      Start a delivery
    </Button>
  </div>
);

const DeliveryList = ({
  title,
  deliveries,
  type,
  onClick,
}: {
  title: string;
  deliveries: Delivery[];
  type: 'accepted' | 'completed';
  onClick: (id: string) => void;
}) => (
  <div className="flex flex-col gap-4">
    <div className="flex w-full items-center justify-between">
      <p className="text-sm">{title}</p>
    </div>
    {deliveries.length === 0 ? (
      <div className="flex w-full flex-col items-center justify-center">
        You have not {type === 'accepted' ? 'accepted' : 'completed'} any delivery.
      </div>
    ) : (
      deliveries.map((delivery) => (
        <DeliveryCard
          key={delivery.id}
          delivery={delivery}
          type={type}
          onClick={() => onClick(delivery.id)}
        />
      ))
    )}
  </div>
);

const VerificationCode = () => {
  const { data, isLoading, error } = useGetDeliveriesByCourierQuery();
  const navigate = useNavigate();

  const viewRequestDetails = (itemId: string) => navigate(`${itemId}`);

  const acceptedDeliveries =
    data?.filter(
      (delivery) =>
        delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER ||
        delivery.deliveryStatus === DeliveryStatus.PICKED_UP ||
        delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
    ) || [];

  const completedDeliveries =
    data?.filter((delivery) => delivery.deliveryStatus === DeliveryStatus.DELIVERED) || [];

  if (isLoading) return <Loader />;

  if (error)
    return (
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        Error fetching deliveries
      </div>
    );

  return (
    <AppLayout>
      {acceptedDeliveries.length === 0 && completedDeliveries.length === 0 ? (
        <NoDeliveries
          message="No Active Delivery. Start a delivery to access this VCode portal."
          onAction={() => navigate('/courier')}
        />
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex items-center justify-between bg-pi-primary-default p-4">
            <h4 className="text-base-white">VCode</h4>
            <ListFilter color="white" />
          </div>
          <div className="flex flex-col gap-4 overflow-auto p-4">
            <DeliveryList
              title="Accepted Deliveries"
              deliveries={acceptedDeliveries}
              type="accepted"
              onClick={viewRequestDetails}
            />
            <DeliveryList
              title="Completed Deliveries"
              deliveries={completedDeliveries}
              type="completed"
              onClick={viewRequestDetails}
            />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default VerificationCode;
